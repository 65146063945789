import { action } from "typesafe-actions";
import { orderActionTypes } from "./order.model";

export const getUserOrderDataRequest = (req) =>
  action(orderActionTypes.GETUSERORDER_REQUEST, req);
export const getUserOrderDataSuccess = (res) =>
  action(orderActionTypes.GETUSERORDER_SUCCESS, res);
export const getUserOrderDataFailure = (err) =>
  action(orderActionTypes.GETUSERORDER_FAILURE, err);

export const orderDispatchRequest = (req) =>
  action(orderActionTypes.ORDERDISPATCH_REQUEST, req);
export const orderDispatchSuccess = (res) =>
  action(orderActionTypes.ORDERDISPATCH_SUCCESS, res);
export const orderDispatchFailure = (err) =>
  action(orderActionTypes.ORDERDISPATCH_FAILURE, err);

export const orderDetailGetRequest = (req) =>
  action(orderActionTypes.ORDERDETAILBYID_REQUEST, req);
export const orderDetailGetSuccess = (res) =>
  action(orderActionTypes.ORDERDETAILBYID_SUCCESS, res);
export const orderDetailGetFailure = (err) =>
  action(orderActionTypes.ORDERDETAILBYID_FAILURE, err);

export const UpdateOrderStatusRequest = (req) =>
  action(orderActionTypes.ORDERDSTATUSUPDADE_REQUEST, req);
export const UpdateOrderStatusSuccess = (res) =>
  action(orderActionTypes.ORDERDSTATUSUPDADE_SUCCESS, res);
export const UpdateOrderStatusFailure = (err) =>
  action(orderActionTypes.ORDERDETAILBYID_FAILURE, err);

export const orderStateResetRequest=(req)=>
  action(orderActionTypes.ORDERSTATE_RESET_REQUEST, req);


export const generatePDFRequest = (req) =>
  action(orderActionTypes.GENERATE_REQUEST, req);
export const generatePDFSuccess = (res) =>
  action(orderActionTypes.GENERATE_SUCCESS, res);
export const generatePDFFailure = (err) =>
  action(orderActionTypes.GENERATE_FAILURE, err);