import { action } from "typesafe-actions";
import { BrandActionType } from "./brand.model";

export const postBrandRequest = (req) =>
  action(BrandActionType.POSTBRAND_REQUEST, req);
export const postBrandSuccess = (res) =>
  action(BrandActionType.POSTBRAND_SUCCESS, res);
export const postBrandFailure = (err) =>
  action(BrandActionType.POSTBRAND_FAILURE, err);

export const updateBrandRequest = (req) =>
  action(BrandActionType.UPDATEBRAND_REQUEST, req);
export const updateBrandSuccess = (res) =>
  action(BrandActionType.UPDATEBRAND_SUCCESS, res);
export const updateBrandFailure = (err) =>
  action(BrandActionType.UPDATEBRAND_FAILURE, err);

export const deleteBrandRequest = (req) =>
  action(BrandActionType.DELETEBRAND_REQUEST, req);
export const deleteBrandSuccess = (res) =>
  action(BrandActionType.DELETEBRAND_SUCCESS, res);
export const deleteBrandFailure = (err) =>
  action(BrandActionType.DELETEBRAND_FAILURE, err);

export const getBrandRequest = (req) =>
  action(BrandActionType.GETBRAND_REQUEST, req);
export const getBrandSuccess = (res) =>
  action(BrandActionType.GETBRAND_SUCCESS, res);
export const getBrandFailure = (err) =>
  action(BrandActionType.GETBRAND_FAILURE, err);
