import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";

export const PostCouponApi=(formData)=>{
    const baseUrl=getBaseEndpointUrl();
    return Http.post(baseUrl+"/coupon/",formData,Http.getAuthToken());
}

export const GetCouponApi=()=>{
    const baseUrl=getBaseEndpointUrl();
    return Http.get(baseUrl+"/coupon/",Http.getAuthToken());
}