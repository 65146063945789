import { dashboardActionTypes } from "./dashboard.model";
import * as dashboardAction from "./dashboard.action";
import { getcardsApi, getDashboardApi, getDueDayApi } from "./dashboard.api";
import { call, put, takeEvery } from "redux-saga/effects";
function* handleGetCardData(action) {
  try {
    const response = yield call(getcardsApi, action.payload);
    yield put(dashboardAction.getCardDataSuccess(response));
  } catch (e) {
    yield put(dashboardAction.getCardDataFailure(e));
  }
}

function* handleGetDashBoardData(action) {
  try {
    const response = yield call(getDashboardApi, action.payload);
    yield put(dashboardAction.getDashBoardDataSuccess(response));
  } catch (e) {
    yield put(dashboardAction.getDashBoardDataFailure(e));
  }
}

function* handleGetDuePayment(action) {
  try {
    const response = yield call(getDueDayApi, action.payload);
    yield put(dashboardAction.dueDayDataSuccess(response));
  } catch (e) {
    yield put(dashboardAction.dueDayDataFailure(e));
  }
}

export function* dashboardSaga() {
  yield takeEvery(dashboardActionTypes.GETCARDDATA_REQUEST, handleGetCardData);
  yield takeEvery(
    dashboardActionTypes.GETDASHBOARDDATA_REQUEST,
    handleGetDashBoardData
  );
  yield takeEvery(
    dashboardActionTypes.PAYMENTDUEDAYS_REQUEST,
    handleGetDuePayment
  );
}
