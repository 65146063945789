import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col, Container } from "react-bootstrap";
// import Nav from "../layout/nav/Nav";
const AppRoute = () => {
  const isAuth = localStorage.getItem("isAuthenticated");
  const isAuthenticated = 1;

  if (!isAuthenticated) {
    localStorage.clear();
  }
  return isAuth ? (
    <>
      {/* <Nav /> */}
      <main>
       
          <Row className="h-100">
            <Col className="h-100" id="contentArea">
              <Outlet />
            </Col>
          </Row>
        
      </main>
    </>
  ) : (
    <Navigate to="/login" />
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(AppRoute);
