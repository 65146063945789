import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import avatar1 from "../../assets/imgs/people/avatar1.jpg";

function Pagesettings(){
    return(
        <>
        
        <Sidebar/>
        <main className="main-wrap">
        <Header/>

        <section className="content-main">
        <div className="content-header">
          <h2 className="content-title">Profile setting</h2>
        </div>
        <div className="card">
          <div className="card-body">
            <Row className="gx-5">
              <aside className="col-lg-3 border-end">
                <nav className="nav nav-pills flex-lg-column mb-4"><a className="nav-link active" aria-current="page" href="#">General</a><a className="nav-link" href="#">Moderators</a><a className="nav-link" href="#">Admin account</a><a className="nav-link" href="#">SEO settings</a><a className="nav-link" href="#">Mail settings</a><a className="nav-link" href="#">Newsletter</a></nav>
              </aside>
              <div className="col-lg-9">
                <section className="content-body p-xl-4">
                  <form>
                    <Row>
                      <Col lg={8}>
                        <Row className="gx-3">
                          <Col md={6} className="mb-3">
                            <label className="form-label">First name</label>
                            <input className="form-control" type="text" placeholder="Type here"/>
                          </Col>
                          <Col md={6} className="mb-3">
                            <label className="form-label">Last name</label>
                            <input className="form-control" type="text" placeholder="Type here"/>
                          </Col>
                          <Col md={6} className="mb-3">
                            <label className="form-label">Email</label>
                            <input className="form-control" type="email" placeholder="example@mail.com"/>
                          </Col>
                          <Col md={6} className="mb-3">
                            <label className="form-label">Phone</label>
                            <input className="form-control" type="tel" placeholder="+1234567890"/>
                          </Col>
                          <Col lg={12} className="mb-3">
                            <label className="form-label">Address</label>
                            <input className="form-control" type="text" placeholder="Type here"/>
                          </Col>
                          <Col lg={6} className="mb-3">
                            <label className="form-label">Birthday</label>
                            <input className="form-control" type="date"/>
                          </Col>
                        </Row>
                      </Col>
                      <aside className="col-lg-4">
                        <figure className="text-lg-center"><img className="img-lg mb-3 img-avatar" src={avatar1} alt="User Photo"/>
                          <figcaption><a className="btn btn-light rounded font-md" href="#"><i className="icons material-icons md-backup font-md"></i> Upload</a></figcaption>
                        </figure>
                      </aside>
                    </Row><br/>
                    <button className="btn btn-primary" type="submit">Save changes</button>
                  </form>
                  <hr className="my-5"/>
                  <div className="row" style={{maxWidth:'920px'}}>
                    <div className="col-md">
                      <article className="box mb-3 bg-light"><a className="btn float-end btn-light btn-sm rounded font-md" href="#">Change</a>
                        <h6>Password</h6><small className="text-muted d-block" style={{width:'70%'}}>You can reset or change your password by clicking here</small>
                      </article>
                    </div>
                    <div className="col-md">
                      <article className="box mb-3 bg-light"><a className="btn float-end btn-light rounded btn-sm font-md" href="#">Deactivate</a>
                        <h6>Remove account</h6><small className="text-muted d-block" style={{width:'70%'}}>Once you delete your account, there is no going back.</small>
                      </article>
                    </div>
                  </div>
                </section>
              </div>
            </Row>
          </div>
        </div>
      </section>

        <Footer/>
        </main>
        </>
    );
}
export default Pagesettings;