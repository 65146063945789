import { getBaseEndpointUrl } from "../../services/config";
import { Http } from "../../services/Http";

export const getUserOrderDataApi = (query) => {
  console.log(query, "3erftyh");
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/orders/list?query=" +
      query.query +
      "&page=" +
      query.page +
      "&limit=" +
      query.limit +
      "&status=" +
      query.status +
      "&customerId=" +
      query.customer +
      "&order_status=" +
      query.order_status +
      "&order_id=" +
      query.order_id +
      "&from_date=" +
      query.from_date +
      "&to_date=" +
      query.to_date,
    Http.getAuthToken()
  );
};

export const postOrderDispatchApi = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/orders/dispatch-status",
    formData,
    Http.getAuthToken()
  );
};
export const orderDetailDataApi = (query) => {
  console.log(query, "3erftyh");
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/orders/" + query?.id, Http.getAuthToken());
};
export const orderStatusUpdateApi = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/orders/status", payload, Http.getAuthToken());
};

export const pdfApi = (query) => {
  console.log(query, "valeusqu")
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/orders/getpdf/"+query, Http.getAuthToken());
};