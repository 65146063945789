function Footer(){
    return(
        <>
            <footer className="main-footer font-xs">
                <div className="row pb-30 pt-15">
                <div className="col-sm-6">
                Copyright  &copy; 2024 Harvest Fresh. All Rights Reserved. Powered by NRJ Digital Garage (P)Ltd.
                </div>
                </div>
            </footer>
        </>
    );
}
export default Footer;