import { Button, Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import item1 from "../../assets/imgs/items/1.jpg";
import item2 from "../../assets/imgs/items/2.jpg";
import item3 from "../../assets/imgs/items/3.jpg";
import item4 from "../../assets/imgs/items/4.jpg";
import brand2 from "../../assets/imgs/card-brands/2.png";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  generatePDFRequest,
  getUserOrderDataRequest,
  orderDetailGetRequest,
  orderStateResetRequest,
  UpdateOrderStatusRequest,
} from "../../store/order/order.action";
import { useEffect } from "react";
import { orderSelector } from "../../store/order/order.model";
import { API_BASE, assestURL } from "../../services/config";
import dayjs from "dayjs";
import { statusClasses, statusData } from "../../utils/Constants";
import noImg from "../../assets/imgs/no-image.jpg";
import {toast} from 'react-toastify'
function Pageordersdetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  /* Selectors */
  const {
    orderDetailData,
    updateStatusSuccess,
    pdfSendsuccess,
    getpdfDataSuccess,
    pdfLoad,
  } = useSelector(orderSelector);
  console.log(orderDetailData, "456789jl");
  /* State */
  /* Fucntion */
  const onStatusChanges = (eve, id) => {
    console.log(eve, id, "evvid");
    const payload = {
      id,
      status: eve,
    };
    dispatch(UpdateOrderStatusRequest(payload));
  };
  const handleRejectClick = (id) => {
    const payload = {
      id,
      order_status: 4,
      status: "",
    };
    console.log(payload, "sss");
    dispatch(UpdateOrderStatusRequest(payload));
  };
  const handleSuccessClick = (id) => {
    const payload = {
      id,
      status: "2",
    };
    console.log(payload, "sss");
    dispatch(UpdateOrderStatusRequest(payload));
  };
  const renderButton = (orderStatus, id, status) => {
    console.log(status, "dddd");
    // console.log(orderStatus,id,"dddd");
    if (status === 2) {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <text className="text-success">Ordered Dispached</text>
        </div>
      );
    }
    switch (orderStatus) {
      case 0:
        // debugger
        return (
          <div className="d-flex justify-content-center align-items-center">
            <Button
              className="p-0"
              variant="danger"
              onClick={() => handleRejectClick(id)}
            >
              Rejected
              {/* <i className="icon material-icons md-clear "></i> */}
            </Button>
          </div>
        );
      case 1:
        return (
          <div className="d-flex justify-content-center align-items-center">
            <Button
              variant="danger"
              className="p-0"
              onClick={() => handleRejectClick(id)}
            >
              Rejected
              {/* <i className="icon material-icons md-clear "></i> */}
            </Button>
          </div>
        );
      case 2:
        return (
          <div className="d-flex justify-content-center align-items-center">
            <Button
              className="p-0"
              variant="danger"
              onClick={() => handleRejectClick(id)}
            >
              Rejected
              {/* <i className="icon material-icons md-clear "></i> */}
            </Button>
            <Button
              variant="success"
              className=" ms-3 p-0 text-light"
              onClick={() => handleSuccessClick(id)}
            >
              Dispatch
              {/* <i className="icon material-icons md-done "></i> */}
            </Button>
          </div>
        );
    }
  };
  /* UseEffect */
  useEffect(() => {
    if (id !== 0) {
      const payload = {
        id: id,
      };

      dispatch(orderDetailGetRequest(payload));
    }
  }, [id]);

  useEffect(() => {
    if (pdfLoad) {
      toast.dismiss();
      toast.warning("PDF loading please wait");
    }
    if (updateStatusSuccess) {
      const payload = {
        id: id,
      };
      dispatch(orderDetailGetRequest(payload));
    }

    if (getpdfDataSuccess) {
      window.open(`${assestURL}${pdfSendsuccess}`, "_blank", "noreferrer");
      dispatch(orderStateResetRequest());
    }
  }, [updateStatusSuccess, pdfSendsuccess, getpdfDataSuccess, pdfLoad]);
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Order detail</h2>
              <p>Details for Order ID: {orderDetailData[0]?.id}</p>
            </div>
          </div>
          <div className="card">
            <header className="card-header">
              <Row className="align-items-center">
                <Col lg={8} md={6} className="mb-lg-0 mb-15">
                  <span>
                    <i className="material-icons md-calendar_today"></i>
                    <b>
                      {" "}
                      {dayjs(orderDetailData[0]?.created_at).format(
                        "ddd, MMM D, YYYY, h:mma"
                      )}
                    </b>
                  </span>
                  <br />
                  <small className="text-muted">Order ID: 3453012</small>
                  <br />
                  <p>
                    {" "}
                    Order Status:{" "}
                    <span
                      className={`badge rounded-pill ${
                        statusClasses[orderDetailData[0]?.status] ||
                        "alert-secondary"
                      }`}
                    >
                      {statusData[orderDetailData[0]?.status]}
                    </span>
                  </p>
                </Col>
                <Col lg={4} md={6} className="ms-auto text-md-end ">
                  <div className="row">
                    <div className="col-8">
                      <div className="row shadow-none p-3 mb-5 bg-light rounded">
                        <div className="col-12 d-flex justify-content-center align-items-center">
                          {" "}
                          Manage State
                        </div>
                        <div className="col-12  d-flex justify-content-center align-items-center">
                          {renderButton(
                            orderDetailData[0]?.order_status,
                            orderDetailData[0]?.id,
                            orderDetailData[0]?.status
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-4 d-flex flex-column justify-content-between">
                      <div className="row">
                        <div className="col-12 colspan-2 ">
                          {" "}
                          <a
                            className="btn btn-secondary print ms-2"
                            href="#"
                            onClick={() =>
                              dispatch(generatePDFRequest(orderDetailData[0]?.id))
                            }
                          >
                            <i className="icon material-icons md-print"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </header>
            <div className="card-body">
              <Row className="mb-50 mt-20 order-info-wrap">
                <Col md={4}>
                  <article className="icontext align-items-start">
                    <span className="icon icon-sm rounded-circle bg-primary-light">
                      <i className="text-primary material-icons md-person"></i>
                    </span>
                    <div className="text">
                      <h6 className="mb-1">Customer</h6>
                      <p className="mb-1">
                        {orderDetailData[0]?.user?.name}
                        <br /> {orderDetailData[0]?.user?.email}
                        <br /> {orderDetailData[0]?.user?.mobile}
                      </p>
                      <Link to="/pagesellerslist">
                        <a href="">View profile</a>
                      </Link>
                    </div>
                  </article>
                </Col>

                <Col md={6}>
                  <article className="icontext align-items-start">
                    <span className="icon icon-sm rounded-circle bg-primary-light">
                      <i className="text-primary material-icons md-place"></i>
                    </span>
                    <div className="text">
                      <h6 className="mb-1">Deliver to</h6>
                      <p className="mb-1">
                        City:
                        {orderDetailData[0]?.user?.city == null
                          ? orderDetailData[0]?.user?.city
                          : "-"}
                        <br />
                        Address:
                        {orderDetailData[0]?.user?.city == null
                          ? orderDetailData[0]?.user?.address
                          : "-"}
                      </p>
                    </div>
                  </article>
                </Col>
              </Row>
              <Row>
                <Col lg={10}>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th width="40%">Product</th>
                          <th width="20%">Unit Price</th>
                          <th width="20%">Quantity</th>
                          <th width="20%">Stock Available</th>
                          <th className="text-end" width="20%">
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderDetailData?.map((ele) =>
                          ele?.orderdetails?.map((prod) => {
                            return (
                              <tr>
                                <td>
                                  <a className="itemside" href="#">
                                    <div className="left">
                                      <img
                                        className="img-xs"
                                        //   src={item1}
                                        src={
                                          prod?.products?.image
                                            ? API_BASE +
                                              "/products" +
                                              prod?.products?.image
                                            : noImg
                                        }
                                        alt="Item"
                                        width="40"
                                        height="40"
                                      />
                                    </div>
                                    <div className="info">
                                      {" "}
                                      {prod?.products?.name}
                                    </div>
                                  </a>
                                </td>
                                <td> {prod?.sale_price}</td>
                                <td> {prod?.quantity}</td>
                                <td>
                                  {prod?.products?.product_inventory &&
                                  prod?.products?.product_inventory[0]
                                    ?.total_qty > 0
                                    ? prod?.products?.product_inventory[0]
                                        ?.total_qty
                                    : "0"}
                                </td>
                                <td className="text-end">
                                  {" "}
                                  {prod?.quantity * prod?.sale_price}
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/* <Link to="/pageorders">
                    <button className="btn btn-primary">
                      View Order Tracking
                    </button>
                  </Link> */}
                </Col>
                <Col lg={1}></Col>
                {/* <Col lg={4}>
                  <div className="box shadow-sm bg-light">
                    <h6 className="mb-15">Payment info</h6>
                    <p>
                      <img className="border" src={brand2} height="20" /> Master
                      Card **** **** 4768
                      <br /> Business name: Grand Market LLC
                      <br /> Phone: +1 (800) 555-154-52
                    </p>
                  </div>
                  <div className="h-25 pt-4">
                    <div className="mb-3">
                      <label>Notes</label>
                      <textarea
                        className="form-control"
                        id="notes"
                        name="notes"
                        placeholder="Type some note"
                      ></textarea>
                    </div>
                    <button className="btn btn-primary">Save note</button>
                  </div>
                </Col> */}
              </Row>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default Pageordersdetail;
