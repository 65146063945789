export const couponAddActionType={
    POSTCOUPON_REQUEST:"@@coupon/POSTCOUPON_REQUEST",
    POSTCOUPON_SUCCESS:"@@coupon/POSTCOUPON_SUCCESS",
    POSTCOUPON_FAILURE:"@@coupon/POSTCOUPON_FAILURE",

    GETCOUPON_REQUEST:"@@coupon/GETCOUPON_REQUEST",
    GETCOUPON_SUCCESS:"@@coupon/GETCOUPON_SUCCESS",
    GETCOUPON_FAILURE:"@@coupon/GETCOUPON_FAILURE"
}
export const couponInitalState={
    postLoading:false,
    postSuccess:false,
    getLoading:false,
    allCoupons:[],
}