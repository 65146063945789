export const dashboardActionTypes = {
  GETCARDDATA_REQUEST: "@@dashboard/GETCARDDATA_REQUEST",
  GETCARDDATA_SUCCESS: "@@dashboard/GETCARDDATA_SUCCESS",
  GETCARDDATA_FAILURE: "@@dashboard/GETCARDDATA_FAILURE",

  GETDASHBOARDDATA_REQUEST: "@@dashboard/GETDASHBOARDDATA_REQUEST",
  GETDASHBOARDDATA_SUCCESS: "@@dashboard/GETDASHBOARDDATA_SUCCESS",
  GETDASHBOARDDATA_FAILURE: "@@dashboard/GETDASHBOARDDATA_FAILURE",

  PAYMENTDUEDAYS_REQUEST: "@@dashboard/PAYMENTDUEDAYS_REQUEST",
  PAYMENTDUEDAYS_SUCCESS: "@@dashboard/PAYMENTDUEDAYS_SUCCESS",
  PAYMENTDUEDAYS_FAILURE: "@@dashboard/PAYMENTDUEDAYS_FAILURE",
};

export const dashboardInitialState = {
  cardLoad: false,
  cardData: [],
  dashLoad: false,
  dashData: [],
  dueLoad:false,
  dueDayData:[]
};

export const dashboardSelector = (state) => state.dashboardReducer;
