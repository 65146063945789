import { dashboardActionTypes, dashboardInitialState } from "./dashboard.model";

const reducer = (state = dashboardInitialState, action) => {
  switch (action.type) {
    case dashboardActionTypes.GETCARDDATA_REQUEST:
      return {
        ...state,
        cardLoad: true,
      };
    case dashboardActionTypes.GETCARDDATA_SUCCESS:
      return {
        ...state,
        cardLoad: false,
        cardData: action.payload?.data?.data,
      };
    case dashboardActionTypes.GETCARDDATA_FAILURE:
      return {
        ...state,
        cardLoad: false,
      };
    case dashboardActionTypes.GETDASHBOARDDATA_REQUEST:
      return {
        ...state,
        dashLoad: true,
      };
    case dashboardActionTypes.GETDASHBOARDDATA_SUCCESS:
      return {
        ...state,
        dashLoad: false,
        dashData: action.payload?.data?.data,
      };
    case dashboardActionTypes.GETDASHBOARDDATA_FAILURE:
      return {
        ...state,
        dashLoad: false,
      };
    case dashboardActionTypes.PAYMENTDUEDAYS_REQUEST:
      return {
        ...state,
        dueLoad: true,
      };
    case dashboardActionTypes.PAYMENTDUEDAYS_SUCCESS:
      return {
        ...state,
        dueLoad: true,
        dueDayData: action.payload?.data?.data,
      };
    case dashboardActionTypes.PAYMENTDUEDAYS_FAILURE:
      return {
        ...state,
        dueLoad: true,
      };
    default:
      return state;
  }
};

export { reducer as dashboardReducer };
