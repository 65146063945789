import React from 'react'
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Card } from "react-bootstrap";
import Setting from './Setting';
import Banner from './Banner';


function PageSetting() {

  return (
    <>
     <Sidebar />
      <main className="main-wrap">
        <Header />
        {/* <Card>
    <Tabs>
        <TabList>
            <Tab>Settings</Tab>
            <Tab>Banner Manage</Tab>
        </TabList>
        <TabPanel>
            <p>jfhjhfjhj</p>
        </TabPanel>
        <TabPanel>
            <p>urtiuetyetyot</p>
        </TabPanel>
    </Tabs>
</Card> */}

     
           
         <section className="content-main">
         <Tabs>
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Settings</h2>

            </div>
          </div>

          <div className="card">
            
            <div className="card-body">
            <TabList className="d-flex nav-tabs">
                <Tab className=" nav-link ">Settings</Tab>
                <Tab className=" nav-link">Banner Manage</Tab>
            </TabList>
                <TabPanel>
                    <Setting/>
                </TabPanel>
                <TabPanel>
                    
                    <Banner/>
                </TabPanel>
            </div>
            </div>
            </Tabs>
          </section>
    <Footer />
      </main>
    </>
  )
}

export default PageSetting