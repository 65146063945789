import { VendorActionTypes, vendorInititalState } from "./vendor.model";
import { decryptdata } from "../../utils/encrypt&decrypt";

const reducer = (state = vendorInititalState, action) => {
  switch (action.type) {
    case VendorActionTypes.GETVENDORDATA_REQUEST:
      return {
        ...state,
        userDataLoading: true,
      };
    case VendorActionTypes.GETVENDORDATA_SUCCESS:
      console.log(action?.payload, process.env.SECRET_KEY, "jkmls");
      let payloaddatas2 = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      console.log(payloaddatas2?.data, "yhoio");
      return {
        ...state,
        userDataLoading: false,
        userData: payloaddatas2?.data,
      };
    case VendorActionTypes.GETVENDORDATA_FAILURE:
      return {
        ...state,
        userDataLoading: false,
      };
    case VendorActionTypes.CREATEVENDORDATA_REQUEST:
      return {
        ...state,
        createUserLoad: true,
        createSuccess: false,
      };
    case VendorActionTypes.CREATEVENDORDATA_SUCCESS:
      return {
        ...state,
        createUserLoad: false,
        createSuccess: true,
      };
    case VendorActionTypes.CREATEVENDORDATA_FAILURE:
      return {
        ...state,
        createUserLoad: false,
        createSuccess: false,
      };
    case VendorActionTypes.UPDATE_VENDORDATA_REQUEST:
      return {
        ...state,
        updateUserLoad: true,
        updateSuccess: false,
      };
    case VendorActionTypes.UPDATE_VENDORDATA_SUCCESS:
      return {
        ...state,
        updateUserLoad: false,
        updateSuccess: true,
      };
    case VendorActionTypes.UPDATE_VENDORDATA_FAILURE:
      return {
        ...state,
        updateUserLoad: false,
        updateSuccess: false,
      };
    case VendorActionTypes.DELETEVENDORDATA_REQUEST:
      return {
        ...state,
        deleteLoad: true,
        deleteLoadSuccess: false,
      };
    case VendorActionTypes.DELETEVENDORDATA_SUCCESS:
      return {
        ...state,
        deleteLoad: false,
        deleteLoadSuccess: true,
      };
    case VendorActionTypes.DELETEVENDORDATA_FAILURE:
      return {
        ...state,
        deleteLoad: false,
        deleteLoadSuccess: false,
      };
      case VendorActionTypes.VENDOR_RESETSTATE:
        return{
          ...state,
          userDataLoading:false,
          createUserLoad:false,
          userData:[],
          createSuccess:false,
          updateUserLoad:false,
          updateSuccess:false,
          deleteLoad:false,
          deleteLoadSuccess:false,
        }
    default:
      return state;
  }
};
export { reducer as VendorReducer };
export const vendorSelector=(state)=>state.VendorReducer;
