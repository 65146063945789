import { getBaseEndpointUrl } from "../../services/config";
import { Http } from "../../services/Http";

export const postProductApi = (formData) => {
  const abortController = new AbortController();
  const prop = { signal: abortController.signal };
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/product/create",
    formData,
    Http.getFileAuthToken()
  );
};
export const getProductApi = (query) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/product?query=" +
      query.query +
      "&prod_id=" +
      query.prod_id +
      "&page=" +
      query.page +
      "&limit=" +
      query.limit,
    {}
  );
};

export const updateProductApi = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(
    baseUrl + "/product/update/" + formData?.id,
    formData,
    Http.getFileAuthToken()
  );
};

export const deleteProductApi = (id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/product/delete/" + id, Http.getAuthToken());
};
export const postProductPriceApi = (formData) => {
  console.log(formData, "5678fsdjf")
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/product/bulkupdate",
    formData,
    Http.getFileAuthToken()
  );
};