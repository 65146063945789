export const AuthActionTypes = {
  SIGNUP_USER_REQUEST: "@@user/SIGNUP_USER_REQUEST",
  SIGNUP_USER_SUCCESS: "@@user/SIGNUP_USER_SUCCESS",
  SIGNUP_USER_FAILURE: "@@user/SIGNUP_USER_FAILURE",

  LOGIN_USER_REQUEST: "@@user/LOGIN_USER_REQUEST",
  LOGIN_USER_SUCCESS: "@@user/LOGIN_USER_SUCCESS",
  LOGIN_USER_FAILURE: "@@user/LOGIN_USER_FAILURE",

  CLEAR_USER_LOGOUT : "@@user/CLEAR_USER_LOGOUT",

  CLEAR_MESSAGES: "@@user/CLEAR_MESSAGES",

  VERIFY_MAIL_REQUEST: "@@auth/VERIFY_MAIL_REQUEST",
  VERIFY_MAIL_SUCCESS: "@@auth/VERIFY_MAIL_SUCCESS",
  VERIFY_MAIL_FAILURE: "@@auth/VERIFY_MAIL_FAILURE",
};

export const authInitialState = {
  user: {},
  isLoading: false,
  groupMessage: "",
  groupError: "",
  values: "",
  error: "",
  auth:{}
};
