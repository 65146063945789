import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import brand2 from "../../assets/imgs/card-brands/2.png";
import item1 from "../../assets/imgs/items/1.jpg";
import item2 from "../../assets/imgs/items/2.jpg";
import item3 from "../../assets/imgs/items/3.jpg";
import item4 from "../../assets/imgs/items/4.jpg";

function Pagetransactiondetails(){
    return(
        <>
        
        <Sidebar/>
        <main className="main-wrap">
        <Header/>

        <section className="content-main">
        <div className="content-header">
          <h2 className="content-title">Transactions Details</h2>
        </div>
        <div className="card">
          <div className="card-body">
            <Row>
              <Col lg={8} className="pr-80">
                <header className="card-header2">
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6 mb-lg-0 mb-15"><span><i className="material-icons md-calendar_today"></i><b>Wed, Aug 13, 2022, 4:34PM</b></span><br/><small className="text-muted">Transaction ID: 3453012</small><br/><small className="text-muted">Transaction Hash: 1a7b7c1b33d161f45804730c70b75175dccd9883</small>
                      <p className="mt-15"><span>Status:</span><span className="badge rounded-pill alert-success text-success">Success</span></p>
                    </div>
                    <div className="col-lg-6 col-md-6 ms-auto text-md-end"><a className="btn btn-primary" href="#">Download PDF</a><a className="btn btn-secondary print ms-2" href="#"><i className="icon material-icons md-print mr-5"></i>Print</a></div>
                  </div>
                </header>
                <hr/>
                <div className="trans-details mb-30">
                  <div className="box shadow-sm bg-light">
                    <div className="row">
                      <div className="col-4">
                        <h6 className="mb-15">Payment info</h6>
                        <p><img className="border" src={brand2} height="20"/> Master Card **** **** 4768<br/>                                                Business name: Grand Market LLC<br/>                                                Phone: +1 (800) 555-154-52</p>
                      </div>
                      <div className="col-4">
                        <h6 className="mb-1">Customer</h6>
                        <p className="mb-1">John Alexander<br/> alex@example.com<br/> +998 99 22123456</p>
                      </div>
                      <div className="col-4">
                        <h6 className="mb-1">Deliver to</h6>
                        <p className="mb-1">City: Tashkent, Uzbekistan<br/>Block A, House 123, Floor 2<br/> Po Box 10000</p>
                      </div>
                    </div>
                  </div>
                </div>
                <h5 className="mb-15">Item List</h5>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th width="40%">Product</th>
                        <th width="20%">Unit Price</th>
                        <th width="20%">Quantity</th>
                        <th className="text-end" width="20%">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><a className="itemside" href="#">
                            <div className="left"><img className="img-xs" src={item1} alt="Item" width="40" height="40"/></div>
                            <div className="info"> T-shirt blue, XXL size</div></a></td>
                        <td> $44.25</td>
                        <td> 2</td>
                        <td className="text-end"> $99.50</td>
                      </tr>
                      <tr>
                        <td><a className="itemside" href="#">
                            <div className="left"><img className="img-xs" src={item2} alt="Item" width="40" height="40"/></div>
                            <div className="info"> Winter jacket for men</div></a></td>
                        <td> $7.50</td>
                        <td> 2</td>
                        <td className="text-end"> $15.00</td>
                      </tr>
                      <tr>
                        <td><a className="itemside" href="#">
                            <div className="left"><img className="img-xs" src={item3} alt="Item" width="40" height="40"/></div>
                            <div className="info"> Jeans wear for men</div></a></td>
                        <td> $43.50</td>
                        <td> 2</td>
                        <td className="text-end"> $102.04</td>
                      </tr>
                      <tr>
                        <td><a className="itemside" href="#">
                            <div className="left"><img className="img-xs" src={item4} alt="Item" width="40" height="40"/></div>
                            <div className="info"> Product name color and size</div></a></td>
                        <td> $99.00</td>
                        <td> 3</td>
                        <td className="text-end"> $297.00</td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <article className="float-end">
                            <dl className="dlist">
                              <dt>Subtotal:</dt>
                              <dd>$973.35</dd>
                            </dl>
                            <dl className="dlist">
                              <dt>Shipping cost:</dt>
                              <dd>$10.00</dd>
                            </dl>
                            <dl className="dlist">
                              <dt>Grand total:</dt>
                              <dd><b className="h5">$983.00</b></dd>
                            </dl>
                          </article>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h5 className="mb-15">Transaction Note</h5>
                <p className="text-muted">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, veritatis at. Dolore facilis repellat numquam cum, id, iste sint libero odio atque a quam ducimus cumque quis enim reiciendis repellendus?</p>
              </Col>
              <Col lg={4}>
                <header className="border-bottom mb-4 pb-4">
                  <Row>
                    <Col md={12}>
                      <h3 className="mb-5 mt-25">Latest Transactions</h3>
                      <hr className="mb-30"/>
                    </Col>
                    <Col lg={8} xs={6} className="me-auto">
                      <input className="form-control" type="text" placeholder="Search..."/>
                    </Col>
                    <Col lg={4} xs={6}>
                      <select className="form-select">
                        <option>Method</option>
                        <option>Master card</option>
                        <option>Visa card</option>
                        <option>Paypal</option>
                      </select>
                    </Col>
                  </Row>
                </header>
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>Transaction ID</th>
                        <th>Paid</th>
                        <th>Date</th>
                        <th className="text-end"> Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><b>#456667</b></td>
                        <td>$294.00</td>
                        <td>18 Jan 2022</td>
                        <td className="text-end"><a className="btn btn-sm btn-light font-sm rounded" href="#">Details</a></td>
                      </tr>
                      <tr>
                        <td><b>#134768</b></td>
                        <td>$294.00</td>
                        <td>18 Jan 2022</td>
                        <td className="text-end"><a className="btn btn-sm btn-light font-sm rounded" href="#">Details</a></td>
                      </tr>
                      <tr>
                        <td><b>#134768</b></td>
                        <td>$294.00</td>
                        <td>18 Jan 2022</td>
                        <td className="text-end"><a className="btn btn-sm btn-light font-sm rounded" href="#">Details</a></td>
                      </tr>
                      <tr>
                        <td><b>#134768</b></td>
                        <td>$294.00</td>
                        <td>18 Jan 2022</td>
                        <td className="text-end"><a className="btn btn-sm btn-light font-sm rounded" href="#">Details</a></td>
                      </tr>
                      <tr>
                        <td><b>#887780</b></td>
                        <td>$294.00</td>
                        <td>18 Jan 2022</td>
                        <td className="text-end"><a className="btn btn-sm btn-light font-sm rounded" href="#">Details</a></td>
                      </tr>
                      <tr>
                        <td><b>#344556</b></td>
                        <td>$294.00</td>
                        <td>18 Jan 2022</td>
                        <td className="text-end"><a className="btn btn-sm btn-light font-sm rounded" href="#">Details</a></td>
                      </tr>
                      <tr>
                        <td><b>#134768</b></td>
                        <td>$294.00</td>
                        <td>18 Jan 2022</td>
                        <td className="text-end"><a className="btn btn-sm btn-light font-sm rounded" href="#">Details</a></td>
                      </tr>
                      <tr>
                        <td><b>#134768</b></td>
                        <td>$294.00</td>
                        <td>18 Jan 2022</td>
                        <td className="text-end"><a className="btn btn-sm btn-light font-sm rounded" href="#">Details</a></td>
                      </tr>
                      <tr>
                        <td><b>#998784</b></td>
                        <td>$294.00</td>
                        <td>18 Jan 2022</td>
                        <td className="text-end"><a className="btn btn-sm btn-light font-sm rounded" href="#">Details</a></td>
                      </tr>
                      <tr>
                        <td><b>#556667</b></td>
                        <td>$294.00</td>
                        <td>18 Jan 2022</td>
                        <td className="text-end"><a className="btn btn-sm btn-light font-sm rounded" href="#">Details</a></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="pagination-area mt-30 mb-50">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-start">
                      <li className="page-item active"><a className="page-link" href="#">1</a></li>
                      <li className="page-item"><a className="page-link" href="#">2</a></li>
                      <li className="page-item"><a className="page-link" href="#">3</a></li>
                      <li className="page-item"><a className="page-link" href="#"><i className="material-icons md-chevron_right"></i></a></li>
                    </ul>
                  </nav>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>

        <Footer/>
        </main>
        </>
    );
}
export default Pagetransactiondetails;