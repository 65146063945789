import { action } from "typesafe-actions";
import { VendorActionTypes } from "./vendor.model";

export const createVendorDataRequest = (req) =>
  action(VendorActionTypes.CREATEVENDORDATA_REQUEST, req);
export const createVendorDataSuccess = (res) =>
  action(VendorActionTypes.CREATEVENDORDATA_SUCCESS, res);
export const createVendorDataFailure = (err) =>
  action(VendorActionTypes.CREATEVENDORDATA_FAILURE, err);

export const getVendorDataRequest = (req) =>
  action(VendorActionTypes.GETVENDORDATA_REQUEST, req);
export const getVendorDataSuccess = (res) =>
  action(VendorActionTypes.GETVENDORDATA_SUCCESS, res);
export const getVendorDataFailure = (err) =>
  action(VendorActionTypes.GETVENDORDATA_FAILURE, err);

export const updateVendorRequest = (req) =>
  action(VendorActionTypes.UPDATE_VENDORDATA_REQUEST, req);
export const updateVendorSuccess = (res) =>
  action(VendorActionTypes.UPDATE_VENDORDATA_SUCCESS, res);
export const updateVendorFailure = (err) =>
  action(VendorActionTypes.UPDATE_VENDORDATA_FAILURE, err);

export const deleteVendorDataRequest = (req) =>
  action(VendorActionTypes.DELETEVENDORDATA_REQUEST, req);
export const deleteVendorDataSuccess = (res) =>
  action(VendorActionTypes.DELETEVENDORDATA_SUCCESS, res);
export const deleteVendorDataFailure = (err) =>
  action(VendorActionTypes.DELETEVENDORDATA_FAILURE, err);

export const resetState=()=>action(VendorActionTypes.VENDOR_RESETSTATE)
