import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";

  export const InventrySendApi=(formData)=>{
    const baseUrl= getBaseEndpointUrl();
    return Http.post(baseUrl+"/inventory/create", formData, Http.getAuthToken())
  }
  export const InventryGetApi=(payload)=>{
    const baseUrl= getBaseEndpointUrl();
    return Http.get(baseUrl+"/inventory/list?query=" +
      payload.query +
      "&page=" +
      payload.page +
      "&limit=" +
      payload.limit, Http.getAuthToken())
  }

  export const InventryDataUpdateApi=(formData)=>{
    const baseUrl= getBaseEndpointUrl();
    return Http.put(baseUrl+"/inventory/update/"+formData?.id,formData?.formData, Http.getAuthToken())
  }
  