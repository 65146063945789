import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";


export const getVendorDataApi = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/vendor?query=" +
      payload.query +
      "&page=" +
      payload.page +
      "&limit=" +
      payload.limit+
      "&userId="+payload.userId,
    Http.getAuthToken()
  );
};
export const createVendorApi = (postData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/vendor/", postData, Http.getAuthToken());
};
export const updateVendorApi = (postData) => {
  console.log(postData, "678")
  const baseUrl = getBaseEndpointUrl();
  return Http.put(baseUrl + "/vendor/"+postData.id,postData.formData, Http.getAuthToken());
};

export const deleteVendorApi = (postData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.delete(baseUrl + "/vendor/"+postData, Http.getAuthToken());
};