import { Form } from "react-bootstrap";
import flagus from "../../src/assets/imgs/theme/flag-us.png";
import flagfr from "../../src/assets/imgs/theme/flag-fr.png";
import flagjp from "../../src/assets/imgs/theme/flag-jp.png";
import flagcn from "../../src/assets/imgs/theme/flag-cn.png";
import avatar2 from "../../src/assets/imgs/people/avatar2.jpg";
import { Link, useNavigate } from "react-router-dom";
import { clearUserLogout } from "../store/auth/auth.action";
import { useDispatch } from "react-redux";

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <header className="main-header navbar">
        <div className="col-search">
          {/* <Form className="searchform">
                    <div className="input-group">
                    <input className="form-control" list="search_terms" type="text" placeholder="Search term"/>
                    <button className="btn btn-light bg" type="button"><i className="material-icons md-search"></i></button>
                    </div>
                    <datalist id="search_terms">
                    <option value="Products"></option>
                    <option value="New orders"></option>
                    <option value="Apple iphone"></option>
                    <option value="Ahmed Hassan"></option>
                    </datalist>
                </Form> */}
        </div>
        <div className="col-nav">
          {/* <button className="btn btn-icon btn-mobile me-auto" data-trigger="#offcanvas_aside"><i className="material-icons md-apps"></i></button> */}
          <ul className="nav">
            <li className="nav-item">
              <a className="nav-link btn-icon" href="#">
                <i className="material-icons md-notifications animation-shake"></i>
                <span className="badge rounded-pill">3</span>
              </a>
            </li>
            {/* <li className="nav-item"><a className="nav-link btn-icon darkmode" href="#"><i className="material-icons md-nights_stay"></i></a></li>
                    <li className="nav-item"><a className="requestfullscreen nav-link btn-icon" href="#"><i className="material-icons md-cast"></i></a></li>
                    <li className="dropdown nav-item"><a className="dropdown-toggle" id="dropdownLanguage" data-bs-toggle="dropdown" href="#" aria-expanded="false"><i className="material-icons md-public"></i></a>
                    <div className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownLanguage"><a className="dropdown-item text-brand" href="#"><img src={flagus} alt="English"/>English</a><a className="dropdown-item" href="#"><img src={flagfr} alt="Français"/>Fran&ccedil;ais</a><a className="dropdown-item" href="#"><img src={flagjp} alt="Français"/>&#x65E5;&#x672C;&#x8A9E;</a><a className="dropdown-item" href="#"><img src={flagcn} alt="Français"/>&#x4E2D;&#x56FD;&#x4EBA;</a></div>
                    </li> */}
            <li className="dropdown nav-item">
              <a
                className="dropdown-toggle"
                id="dropdownAccount"
                data-bs-toggle="dropdown"
                href="#"
                aria-expanded="false"
              >
                <img
                  className="img-xs rounded-circle"
                  src={avatar2}
                  alt="User"
                />
              </a>
              <div
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="dropdownAccount"
              >
                <a className="dropdown-item" href="#">
                  <i className="material-icons md-perm_identity"></i>Edit
                  Profile
                </a>
                {/* <a className="dropdown-item" href="#">
                  <i className="material-icons md-settings"></i>Account Settings
                </a>
                <a className="dropdown-item" href="#">
                  <i className="material-icons md-account_balance_wallet"></i>
                  Wallet
                </a>
                <a className="dropdown-item" href="#">
                  <i className="material-icons md-receipt"></i>Billing
                </a>
                <a className="dropdown-item" href="#">
                  <i className="material-icons md-help_outline"></i>Help center
                </a> */}
                <div className="dropdown-divider"></div>
                <button
                  className="dropdown-item text-danger"
                  onClick={() => {
                    localStorage.clear();
                    dispatch(clearUserLogout());
                    navigate("/login");
                  }}
                >
                  <i className="material-icons md-exit_to_app"></i>Logout
                </button>
              </div>
            </li>
          </ul>
        </div>
      </header>
    </>
  );
}
export default Header;
