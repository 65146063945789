export const VendorActionTypes = {
  GETVENDORDATA_REQUEST: "@@vendor/GETVENDORDATA_REQUEST",
  GETVENDORDATA_SUCCESS: "@@vendor/GETVENDORDATA_SUCCESS",
  GETVENDORDATA_FAILURE: "@@vendor/GETVENDORDATA_FAILURE",

  CREATEVENDORDATA_REQUEST: "@@vendor/CREATEVENDORDATA_REQUEST",
  CREATEVENDORDATA_SUCCESS: "@@vendor/CREATEVENDORDATA_SUCCESS",
  CREATEVENDORDATA_FAILURE: "@@vendor/CREATEVENDORDATA_FAILURE",

  DELETEVENDORDATA_REQUEST: "@@vendor/DELETEVENDORDATA_REQUEST",
  DELETEVENDORDATA_SUCCESS: "@@vendor/DELETEVENDORDATA_SUCCESS",
  DELETEVENDORDATA_FAILURE: "@@vendor/DELETEVENDORDATA_FAILURE",

  UPDATE_VENDORDATA_REQUEST: "@@vendor/UPDATE_VENDORDATA_REQUEST",
  UPDATE_VENDORDATA_SUCCESS: "@@vendor/UPDATE_VENDORDATA_SUCCESS",
  UPDATE_VENDORDATA_FAILURE: "@@vendor/UPDATE_VENDORDATA_FAILURE",

  VENDOR_RESETSTATE:"@@Vendor/VENDOR_RESETSTATE"
};

export const vendorInititalState={
    userDataLoading:false,
  userData:[],
  createUserLoad:false,
  createSuccess:false,
  updateUserLoad:false,
  updateSuccess:false,
  deleteLoad:false,
  deleteLoadSuccess:false,
}
