const localHost = "localhost";
export const API_BASE = (process.env.REACT_APP_NODE_ENV === "uat") ? process.env.REACT_APP_NODEUATASSETURL :process.env.REACT_APP_NODELOCALASSETURL  || 'http://192.168.0.110:4000/'
const localhostEndpoint = `${API_BASE}api/v1`;
console.log(process.env.REACT_APP_NODE_ENV);
const localHostBaseEndpoint = (process.env.REACT_APP_NODE_ENV === "uat") ? process.env.REACT_APP_NODEUATURL : (process.env.REACT_APP_NODE_ENV === "development") ? process.env.REACT_APP_NODELOCALURL : localhostEndpoint;
export const assestURL = (process.env.REACT_APP_NODE_ENV === "uat") ? process.env.REACT_APP_NODEUATASSETURL : (process.env.REACT_APP_NODE_ENV === "development") ? process.env.REACT_APP_NODELOCALASSETURL : process.env.REACT_APP_NODEPRODASSETURL;
export const secretKey = (process.env.SECRET) ? process.env.SECRET : "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c";
export const DateFormat = (process.env.REACT_APP_DATEFORMAT) ? process.env.REACT_APP_DATEFORMAT : 'MM/DD/YYYY';
export const DateTimeFormat = (process.env.REACT_APP_DATETIMEFORMAT) ? process.env.REACT_APP_DATETIMEFORMAT : 'MM/DD/YYYY hh:mm:ss A';

export const getBaseEndpointUrl = () => {
  return localHostBaseEndpoint;
};

function getHostURL(hostName) {
  if (hostName.includes(localHost)) {
    return localHostBaseEndpoint;
  }
}

export function loadScript(src) {
  return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
          resolve(true);
      };
      script.onerror = () => {
          resolve(false);
      };
      document.body.appendChild(script);
  });
}

export const LinkedInApi = {
  clientId: '86gn6k32luyj4w',
  redirectUrl: "http://localhost:4000/api/v1/social/auth_linked",
  oauthUrl: 'https://www.linkedin.com/oauth/v2/authorization?response_type=code',
  scope: 'r_liteprofile%20r_emailaddress',
  state: '123456'
};

export const NodeServer = {
  baseURL: 'http://localhost:3002/',
  getUserCredentials: '/getUserCredentials'
};