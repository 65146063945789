import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import staractive from "../../assets/imgs/icons/stars-active.svg";
import stardisable from "../../assets/imgs/icons/starts-disable.svg";
import Sidebar from "../Sidebar";
import Header from "../Header";

function Pagereviews(){
    return(
        <>
        
        <Sidebar/>
        <main className="main-wrap">
        <Header/>

        <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">Reviews</h2>
            <p>Lorem ipsum dolor sit amet.</p>
          </div>
          <div>
            <input className="form-control bg-white" type="text" placeholder="Search by name"/>
          </div>
        </div>
        <div className="card mb-4">
          <header className="card-header">
            <Row className="gx-3">
              <Col lg={4} md={6} className=" me-auto">
                <input className="form-control" type="text" placeholder="Search..."/>
              </Col>
              <div className="col-lg-2 col-md-3 col-6">
                <select className="form-select">
                  <option>Status</option>
                  <option>Active</option>
                  <option>Disabled</option>
                  <option>Show all</option>
                </select>
              </div>
              <div className="col-lg-2 col-md-3 col-6">
                <select className="form-select">
                  <option>Show 20</option>
                  <option>Show 30</option>
                  <option>Show 40</option>
                </select>
              </div>
            </Row>
          </header>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value=""/>
                      </div>
                    </th>
                    <th>#ID</th>
                    <th>Product</th>
                    <th>Name</th>
                    <th>Rating</th>
                    <th>Date</th>
                    <th className="text-end">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value=""/>
                      </div>
                    </td>
                    <td>23</td>
                    <td><b>A-Line Mini Dress in Blue</b></td>
                    <td>Devon Lane</td>
                    <td>
                      <ul className="rating-stars">
                        <li className="stars-active" style={{width: '60%'}}><img src={staractive} alt="stars"/></li>
                        <li><img src={stardisable} alt="stars"/></li>
                      </ul>
                    </td>
                    <td>10.03.2022</td>
                    <td className="text-end"><a className="btn btn-md rounded font-sm" href="#">Detail</a>
                      <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                        <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value=""/>
                      </div>
                    </td>
                    <td>24</td>
                    <td><b>Fashion Woman Bag</b></td>
                    <td>Guy Hawkins</td>
                    <td>
                      <ul className="rating-stars">
                        <li className="stars-active" style={{width: '80%'}}><img src={staractive} alt="stars"/></li>
                        <li><img src={stardisable} alt="stars"/></li>
                      </ul>
                    </td>
                    <td>04.12.2019</td>
                    <td className="text-end"><a className="btn btn-md rounded font-sm" href="#">Detail</a>
                      <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                        <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value=""/>
                      </div>
                    </td>
                    <td>25</td>
                    <td><b>Air Jordan 1 Top 3 Sneaker (DS)</b></td>
                    <td>Savannah Nguyen</td>
                    <td>
                      <ul className="rating-stars">
                        <li className="stars-active" style={{width: '90%'}}><img src={staractive} alt="stars"/></li>
                        <li><img src={stardisable} alt="stars"/></li>
                      </ul>
                    </td>
                    <td>25.05.2022</td>
                    <td className="text-end"><a className="btn btn-md rounded font-sm" href="#">Detail</a>
                      <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                        <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value=""/>
                      </div>
                    </td>
                    <td>26</td>
                    <td><b>Samsung Galaxy S20 (SM-G980F/DS) Dual SIM</b></td>
                    <td>Kristin Watson</td>
                    <td>
                      <ul className="rating-stars">
                        <li className="stars-active" style={{width: '90%'}}><img src={staractive} alt="stars"/></li>
                        <li><img src={stardisable} alt="stars"/></li>
                      </ul>
                    </td>
                    <td>01.06.2022</td>
                    <td className="text-end"><a className="btn btn-md rounded font-sm" href="#">Detail</a>
                      <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                        <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value=""/>
                      </div>
                    </td>
                    <td>27</td>
                    <td><b>Macbook Pro 16 inch (2022 ) For Sale</b></td>
                    <td>Jane Cooper</td>
                    <td>
                      <ul className="rating-stars">
                        <li className="stars-active" style={{width: '100%'}}><img src={staractive} alt="stars"/></li>
                        <li><img src={stardisable} alt="stars"/></li>
                      </ul>
                    </td>
                    <td>13.03.2022</td>
                    <td className="text-end"><a className="btn btn-md rounded font-sm" href="#">Detail</a>
                      <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                        <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value=""/>
                      </div>
                    </td>
                    <td>28</td>
                    <td><b>Gaming Chair, local pickup only</b></td>
                    <td>Courtney Henry</td>
                    <td>
                      <ul className="rating-stars">
                        <li className="stars-active" style={{width: '100%'}}><img src={staractive} alt="stars"/></li>
                        <li><img src={stardisable} alt="stars"/></li>
                      </ul>
                    </td>
                    <td>21.02.2022</td>
                    <td className="text-end"><a className="btn btn-md rounded font-sm" href="#">Detail</a>
                      <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                        <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value=""/>
                      </div>
                    </td>
                    <td>29</td>
                    <td><b>Coach Tabby 26 for sale</b></td>
                    <td>Ralph Edwards</td>
                    <td>
                      <ul className="rating-stars">
                        <li className="stars-active" style={{width: '90%'}}><img src={staractive} alt="stars"/></li>
                        <li><img src={stardisable} alt="stars"/></li>
                      </ul>
                    </td>
                    <td>23.03.2022</td>
                    <td className="text-end"><a className="btn btn-md rounded font-sm" href="#">Detail</a>
                      <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                        <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value=""/>
                      </div>
                    </td>
                    <td>30</td>
                    <td><b>Oneplus 7 Pro GM1910 256GB</b></td>
                    <td>Courtney Henry</td>
                    <td>
                      <ul className="rating-stars">
                        <li className="stars-active" style={{width: '70%'}}><img src={staractive} alt="stars"/></li>
                        <li><img src={stardisable} alt="stars"/></li>
                      </ul>
                    </td>
                    <td>20.02.2022</td>
                    <td className="text-end"><a className="btn btn-md rounded font-sm" href="#">Detail</a>
                      <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                        <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value=""/>
                      </div>
                    </td>
                    <td>31</td>
                    <td><b>DJI Mavic Pro 2</b></td>
                    <td>Theresa Webb</td>
                    <td>
                      <ul className="rating-stars">
                        <li className="stars-active" style={{width: '20%'}}><img src={staractive} alt="stars"/></li>
                        <li><img src={stardisable} alt="stars"/></li>
                      </ul>
                    </td>
                    <td>10.03.2022</td>
                    <td className="text-end"><a className="btn btn-md rounded font-sm" href="#">Detail</a>
                      <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                        <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value=""/>
                      </div>
                    </td>
                    <td>32</td>
                    <td><b>Heimer Miller Sofa (Mint Condition)</b></td>
                    <td>Darrell Steward</td>
                    <td>
                      <ul className="rating-stars">
                        <li className="stars-active" style={{width: '80%'}}><img src={staractive} alt="stars"/></li>
                        <li><img src={stardisable} alt="stars"/></li>
                      </ul>
                    </td>
                    <td>10.04.2022</td>
                    <td className="text-end"><a className="btn btn-md rounded font-sm" href="#">Detail</a>
                      <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                        <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value=""/>
                      </div>
                    </td>
                    <td>33</td>
                    <td><b>Dell XPS 13 9380, 13.3" 4K UHD (3840X2160)</b></td>
                    <td>Leslie Alexander</td>
                    <td>
                      <ul className="rating-stars">
                        <li className="stars-active" style={{width: '50%'}}><img src={staractive} alt="stars"/></li>
                        <li><img src={stardisable} alt="stars"/></li>
                      </ul>
                    </td>
                    <td>25.05.2022</td>
                    <td className="text-end"><a className="btn btn-md rounded font-sm" href="#">Detail</a>
                      <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                        <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value=""/>
                      </div>
                    </td>
                    <td>34</td>
                    <td><b>Fashion Woman Bag</b></td>
                    <td>Esther Howard</td>
                    <td>
                      <ul className="rating-stars">
                        <li className="stars-active" style={{width: '100%'}}><img src={staractive} alt="stars"/></li>
                        <li><img src={stardisable} alt="stars"/></li>
                      </ul>
                    </td>
                    <td>07.04.2022</td>
                    <td className="text-end"><a className="btn btn-md rounded font-sm" href="#">Detail</a>
                      <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                        <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value=""/>
                      </div>
                    </td>
                    <td>35</td>
                    <td><b>iPad Pro 2017 Model</b></td>
                    <td>Savannah Nguyen</td>
                    <td>
                      <ul className="rating-stars">
                        <li className="stars-active" style={{width: '100%'}}><img src={staractive} alt="stars"/></li>
                        <li><img src={stardisable} alt="stars"/></li>
                      </ul>
                    </td>
                    <td>10.10.2019</td>
                    <td className="text-end"><a className="btn btn-md rounded font-sm" href="#">Detail</a>
                      <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                        <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="pagination-area mt-30 mb-50">
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-start">
              <li className="page-item active"><a className="page-link" href="#">01</a></li>
              <li className="page-item"><a className="page-link" href="#">02</a></li>
              <li className="page-item"><a className="page-link" href="#">03</a></li>
              <li className="page-item"><a className="page-link dot" href="#">...</a></li>
              <li className="page-item"><a className="page-link" href="#">16</a></li>
              <li className="page-item"><a className="page-link" href="#"><i className="material-icons md-chevron_right"></i></a></li>
            </ul>
          </nav>
        </div>
      </section>

        <Footer/>
        </main>

        </>
    );
}
export default Pagereviews;