export const BrandActionType = {
    POSTBRAND_REQUEST: "@@brannd/POSTBRAND_REQUEST",
    POSTBRAND_SUCCESS: "@@brannd/POSTBRAND_SUCCESS",
    POSTBRAND_FAILURE: "@@brannd/POSTBRAND_FAILURE",
  
    GETBRAND_REQUEST: "@@brannd/GETBRAND_REQUEST",
    GETBRAND_SUCCESS: "@@brannd/GETBRAND_SUCCESS",
    GETBRAND_FAILURE: "@@brannd/GETBRAND_FAILURE",
  
    UPDATEBRAND_REQUEST: "@@brannd/UPDATEBRAND_REQUEST",
    UPDATEBRAND_SUCCESS: "@@brannd/UPDATEBRAND_SUCCESS",
    UPDATEBRAND_FAILURE: "@@brannd/UPDATEBRAND_FAILURE",
  
  
    DELETEBRAND_REQUEST: "@@brannd/DELETEBRAND_REQUEST",
    DELETEBRAND_SUCCESS: "@@brannd/DELETEBRAND_SUCCESS",
    DELETEBRAND_FAILURE: "@@brannd/DELETEBRAND_FAILURE",
}

export const BrandInitialState = {
    postLoading: false,
    getLoading: false,
    sendSuccess: false,
    getBrandData: [],
    updateBrandLoading:false,
    BrandUpdatedSuccess:false,
    deleteBrandLoading:false,
    brandDeleteSuccess:false,
}

export const BrandSelector = (state) => state.BrandReducer;
