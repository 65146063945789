import { couponAddActionType, couponInitalState } from "./coupon.model";

const reducer = (state = couponInitalState, action) => {
  switch (action.type) {
    case couponAddActionType.POSTCOUPON_REQUEST:
      return {
        ...state,
        postLoading: true,
        postSuccess: false,
      };
    case couponAddActionType.POSTCOUPON_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postSuccess: true,
      };
    case couponAddActionType.POSTCOUPON_FAILURE:
      return {
        ...state,
        postLoading: false,
        postSuccess: false,
      };
    case couponAddActionType.GETCOUPON_REQUEST:
      return {
        ...state,
        getLoading: true,
        
      };
    case couponAddActionType.GETCOUPON_SUCCESS:
      return {
        ...state,
        getLoading: false,
        allCoupons:action.payload
      };
    case couponAddActionType.GETCOUPON_FAILURE:
      return {
        ...state,
        getLoading: false,
     
      };
    default:
      return state;
  }
};
export { reducer as couponReducer };
