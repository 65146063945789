import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";

function Pageblank(){
    return(
        <>
        
        <Sidebar/>
        <main className="main-wrap">
        <Header/>

        <section className="content-main">
        </section>

        <Footer/>
        </main>
        </>
    );
}
export default Pageblank;