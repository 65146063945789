import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import error from "../../assets/imgs/theme/404.svg";

function Pageerror(){
    return(
        <>
        
        <Sidebar/>
        <main className="main-wrap">
        <Header/>

        <section className="content-main">
        <Row className="mt-60">
          <Col sm={12}>
            <div className="w-50 mx-auto text-center"><img src={error} alt="Page Not Found"/>
              <h3 className="mt-40 mb-15">Oops! Page not found</h3>
              <p>It&apos;s looking like you may have taken a wrong turn. Don&apos;t worry... it happens to the best of us. Here&apos;s a little tip that might help you get back on track.</p><a className="btn btn-primary mt-4" href="index.html"><i className="material-icons md-keyboard_return"></i> Back to main</a>
            </div>
          </Col>
        </Row>
      </section>

        <Footer/>
        </main>
        </>
    );
}
export default Pageerror;