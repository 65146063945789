import { action } from "typesafe-actions";
import { dashboardActionTypes } from "./dashboard.model";

export const getCardDataRequest = (req)=>
    action(dashboardActionTypes.GETCARDDATA_REQUEST, req);
  export const getCardDataSuccess=(res)=>
    action(dashboardActionTypes.GETCARDDATA_SUCCESS, res);
  export const getCardDataFailure=(err)=>
    action(dashboardActionTypes.GETCARDDATA_FAILURE, err);
  
  export const getDashBoardDataRequest = (req)=>
    action(dashboardActionTypes.GETDASHBOARDDATA_REQUEST, req)
  export const getDashBoardDataSuccess=(res)=>
    action(dashboardActionTypes.GETDASHBOARDDATA_SUCCESS, res)
  export const getDashBoardDataFailure=(err)=>
    action(dashboardActionTypes.GETDASHBOARDDATA_FAILURE, err)


  export const dueDayDataRequest = (req)=>
    action(dashboardActionTypes.PAYMENTDUEDAYS_REQUEST, req)
  export const dueDayDataSuccess=(res)=>
    action(dashboardActionTypes.PAYMENTDUEDAYS_SUCCESS, res)
  export const dueDayDataFailure=(err)=>
    action(dashboardActionTypes.PAYMENTDUEDAYS_FAILURE, err)