import { action } from "typesafe-actions";
import { couponAddActionType } from "./coupon.model";

export const postCouponRequest=(req) =>
    action(couponAddActionType.POSTCOUPON_REQUEST,req)
export const postCouponSuccess=(res)=>
    action(couponAddActionType.POSTCOUPON_SUCCESS,res)
export const postCouponFailure=(err)=>
    action(couponAddActionType.POSTCOUPON_FAILURE,err)


export const getCouponRequest=(req) =>
    action(couponAddActionType.GETCOUPON_REQUEST,req)
export const getCouponSuccess=(res)=>
    action(couponAddActionType.GETCOUPON_SUCCESS,res)
export const getCouponFailure=(err)=>
    action(couponAddActionType.GETCOUPON_FAILURE,err)