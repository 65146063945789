
import { call, put, takeEvery } from "redux-saga/effects"
import *as InventryAction from "./Action"
import { InventeryManageActionType } from "./Model"
import { InventryDataUpdateApi, InventryGetApi, InventrySendApi } from "./Api"
import Swal from "sweetalert2";
function* hanldedata(action){
    try {
        const response= yield call(InventrySendApi, action.payload);
        yield put(InventryAction.postInventryDataSuccess(response));

        Swal.fire({
            title: "SuccessFul",
            text: "Inventry Data Add Succesfully!",
            icon: "success",
          });
    } catch (e) {
        yield put(InventryAction.postInventryDataFailure(e))
    }
}
function* hanldeGetdata(action){
    try {
       const response=yield call(InventryGetApi,action.payload);
       yield put(InventryAction.getInventryDataSuccess(response));

    } catch (error) {
       yield put(InventryAction.getInventryDataFailure(error));
        
    }
}

function* hanldeUpdatedata(action){
    console.log(action.payload, "yguhj566")
    try {
       const response=yield call(InventryDataUpdateApi, action.payload);
       yield put(InventryAction.updateInventryDataSuccess(response));

    } catch (error) {
       yield put(InventryAction.updateInventryDataFailure(error));
        
    }
}

export function* InventrySaga() {
    yield takeEvery(InventeryManageActionType.POSTMANAGEINVENTRY_REQUEST, hanldedata)
    yield takeEvery(InventeryManageActionType.GETNVENTRYDATA_REQUEST, hanldeGetdata)
    yield takeEvery(InventeryManageActionType.UPDATEINVENTRYDATA_REQUEST, hanldeUpdatedata)
}