import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import uploads from "../../assets/imgs/theme/upload.svg";

function Pageformproduct() {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <Row>
            <Col md={12}>
              <div className="content-header">
                <h2 className="content-title">Set Profit</h2>
                <div>
                  {/* <button className="btn btn-light rounded font-sm mr-5 text-body hover-up">Save to draft</button> */}
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="card mb-4">
                <div className="card-header">
                  <h4>Profit by percentage or price</h4>
                </div>
                <div className="card-body">
                  <form>
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="mb-4">
                          <label className="form-label">Category</label>
                          <div className="row gx-2"></div>
                          <select className="form-select">
                            <option> All</option>
                            <option> Switches</option>
                            <option> Adapter</option>
                            <option> Panels</option>
                            <option> Wires</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="mb-4">
                          <label className="form-label">Brand</label>
                          <div className="row gx-2"></div>
                          <select className="form-select">
                            <option> All</option>
                            <option> Finolex</option>
                            <option> Legrand</option>
                            <option> Anchor</option>
                            <option> Wires</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="mb-4">
                          <label className="form-label">Profit Category</label>
                          <div className="row gx-2"></div>
                          <select className="form-select">
                            <option> All</option>
                            <option> A</option>
                            <option> B</option>
                            <option> C</option>
                            <option> D</option>
                            <option> E</option>
                            <option> F</option>
                            <option> G</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="mb-4">
                          <label className="form-label">Profit by price</label>
                          <input
                            className="form-control"
                            placeholder="₹"
                            type="number"
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="mb-4">
                          <label className="form-label">
                            Profit by percentage
                          </label>
                          <input
                            className="form-control"
                            placeholder="%"
                            type="number"
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <label className="form-label">&nbsp;</label>
                        <button className="form-control btn btn-md rounded font-sm hover-up ">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="card mb-4">
                <div className="card-header">
                  <Row>
                    <Col lg={10}>
                      <h4>Products filtered</h4>
                    </Col>
                    <Col lg={1}>
                      <button className="form-control btn btn-md rounded font-sm hover-up ">
                        Export
                      </button>
                    </Col>
                    <Col lg={1}>
                      <button className="form-control btn btn-md rounded font-sm hover-up ">
                        Publish
                      </button>
                    </Col>
                  </Row>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>Product name</th>
                          <th>Category</th>
                          <th>Current Price</th>
                          <th>Profit Percentage</th>
                          <th>Updated Price</th>
                          <th className="text-end"> Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Anchor Switch</td>
                          <td>A</td>
                          <td>50.00</td>
                          <td>10%</td>
                          <td>55</td>
                          <td className="text-end">
                            <a
                              className="btn btn-sm btn-brand rounded font-sm mt-15"
                              href="#"
                            >
                              Remove
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Legrand Switch 6A</td>
                          <td>A</td>
                          <td>50.00</td>
                          <td>10%</td>
                          <td>55</td>
                          <td className="text-end">
                            <a
                              className="btn btn-sm btn-brand rounded font-sm mt-15"
                              href="#"
                            >
                              Remove
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Finolex wires</td>
                          <td>A</td>
                          <td>50.00</td>
                          <td>10%</td>
                          <td>55</td>
                          <td className="text-end">
                            <a
                              className="btn btn-sm btn-brand rounded font-sm mt-15"
                              href="#"
                            >
                              Remove
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>GM bells</td>
                          <td>A</td>
                          <td>50.00</td>
                          <td>10%</td>
                          <td>55</td>
                          <td className="text-end">
                            <a
                              className="btn btn-sm btn-brand rounded font-sm mt-15"
                              href="#"
                            >
                              Remove
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>MCB</td>
                          <td>A</td>
                          <td>50.00</td>
                          <td>10%</td>
                          <td>55</td>
                          <td className="text-end">
                            <a
                              className="btn btn-sm btn-brand rounded font-sm mt-15"
                              href="#"
                            >
                              Remove
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="pagination-area mt-15 mb-50">
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-start">
                        <li className="page-item active">
                          <a className="page-link" href="#">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link dot" href="#">
                            ...
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            16
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            <i className="material-icons md-chevron_right"></i>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default Pageformproduct;
