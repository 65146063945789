import { call,put,takeEvery } from "redux-saga/effects";
import { couponAddActionType } from "./coupon.model";
import * as couponAddAction  from "./coupon.action";
import Swal from "sweetalert2";
import { GetCouponApi, PostCouponApi } from "./coupon.api";

function* handleSubmit(action){
    try{
        console.log(action, "5trgybb");
        const response= yield  call(PostCouponApi,action.payload);
        yield put(couponAddAction.postCouponSuccess(response))
       
        Swal.fire({
          title: "Success",
          text: "Catergory List Added Succesfully!",
          icon: "success",
        });
    }
    catch(e){
        yield put(couponAddAction.postCouponFailure(e));
        Swal.fire({
            title: "Error",
            text: e?.response?.data?.message,
            icon: "error",
          });
    }
}
function* getCoupons(action){
    try{
        const response= yield  call(GetCouponApi);
        yield put(couponAddAction.getCouponSuccess(response))
        // console.log(response, "5trgybb");
        Swal.fire({
          title: "Success",
          text: "Catergory List Added Succesfully!",
          icon: "success",
        });
    }
    catch(e){
        yield put(couponAddAction.getCouponFailure(e));
        Swal.fire({
            title: "Error",
            text: e?.response?.data?.message,
            icon: "error",
          });
    }
}

export function* CouponSaga(){
    yield takeEvery(couponAddActionType.POSTCOUPON_REQUEST,handleSubmit);
    yield takeEvery(couponAddActionType.GETCOUPON_REQUEST,getCoupons);
}