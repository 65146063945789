import { decryptdata } from "../../utils/encrypt&decrypt";
import { BrandInitialState,BrandActionType } from "./brand.model";

const reducer = (state = BrandInitialState, action) => {
  switch (action.type) {
    case BrandActionType.POSTBRAND_REQUEST:
      return {
        ...state,
        postLoading: true,
        sendSuccess: false,
      };
    case BrandActionType.POSTBRAND_SUCCESS:
      return {
        ...state,
        postLoading: false,
        sendSuccess: true,
      };
    case BrandActionType.POSTBRAND_FAILURE:
      return {
        ...state,
        postLoading: false,
        sendSuccess: false,
      };
    case BrandActionType.GETBRAND_REQUEST:
      return {
        ...state,
        getLoading: true,
      };
    case BrandActionType.GETBRAND_SUCCESS:
      const { payload } = action;
      console.log(payload, process.env.SECRET_KEY, "jkmls");
      let payloaddatas = JSON.parse(
        decryptdata(
          payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      console.log(payloaddatas.data, "yhoio");
      return {
        ...state,
        getLoading: false,
        getBrandData: payloaddatas,
      };
    case BrandActionType.GETBRAND_FAILURE:
      return {
        ...state,
        getLoading: false,
      };

    case BrandActionType.UPDATEBRAND_REQUEST:
      return {
        ...state,
        updateCatLoading: true,
        BrandUpdatedSuccess: false,
      };
    case BrandActionType.UPDATEBRAND_SUCCESS:
      return {
        ...state,
        updateCatLoading: false,
        BrandUpdatedSuccess: true,
      };
    case BrandActionType.UPDATEBRAND_FAILURE:
      return {
        ...state,
        updateCatLoading: false,
        BrandUpdatedSuccess: false,
      };

    case BrandActionType.DELETEBRAND_REQUEST:
      return {
        ...state,
        deleteBrandLoading: true,
        brandDeleteSuccess: false,
      };
    case BrandActionType.DELETEBRAND_SUCCESS:
      return {
        ...state,
        deleteBrandLoading: false,
        brandDeleteSuccess: true,
      };
    case BrandActionType.DELETEBRAND_FAILURE:
      return {
        ...state,
        deleteBrandLoading: false,
        brandDeleteSuccess: false,
      };
      default:
        return state;
    }
  };
  export { reducer as BrandReducer };
  