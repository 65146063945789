


import { all, call, put, takeEvery } from "redux-saga/effects";
import * as VendorAction from "./vendor.action";
import {
  createVendorApi,
  deleteVendorApi,
  getVendorDataApi,
  updateVendorApi,
} from "./vendor.api";
import { VendorActionTypes } from "./vendor.model";


function* handlegetVendorData(action) {
  try {
    const response = yield call(getVendorDataApi, action.payload);
    yield put(VendorAction.getVendorDataSuccess(response));
  } catch (e) {
    yield put(VendorAction.getVendorDataFailure(e));
  }
}
function* handleCreateVendor(action) {
  try {
    const response = yield call(createVendorApi, action.payload);
    yield put(VendorAction.createVendorDataSuccess(response));
  } catch (e) {
    yield put(VendorAction.createVendorDataFailure(e));
  }
}
function* handleUpdateVendorData(action) {
  console.log(action.payload, "actusdjf")
  try {
    const response = yield call(updateVendorApi, action.payload);
    yield put(VendorAction.updateVendorSuccess(response));
  } catch (e) {
    yield put(VendorAction.updateVendorFailure(e));
  }
}

function* handleDeleteVendor(action) {
  try {
    const response = yield call(deleteVendorApi, action.payload);
    yield put(VendorAction.deleteVendorDataSuccess(response));
  } catch (e) {
    yield put(VendorAction.deleteVendorDataFailure(e));
  }
}

export function* VendorSaga() {
  yield takeEvery(VendorActionTypes.GETVENDORDATA_REQUEST, handlegetVendorData);
  yield takeEvery(VendorActionTypes.CREATEVENDORDATA_REQUEST, handleCreateVendor);
  yield takeEvery(VendorActionTypes.UPDATE_VENDORDATA_REQUEST, handleUpdateVendorData);
  yield takeEvery(VendorActionTypes.DELETEVENDORDATA_REQUEST, handleDeleteVendor);

}
