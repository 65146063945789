import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";

export const postCatApi = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/category/create", formData, Http.getAuthToken());
};
export const getCatApi = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/category?query=" +
      payload.query +
      "&page=" +
      payload.page +
      "&limit=" +
      payload.limit,
    Http.getAuthToken()
  );
};

export const updateCatergoryApi = ({ formData, id }) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(
    baseUrl + "/category/update/" + id,
    formData,
    Http.getAuthToken()
  );
};

export const deleteCatergoryApi = (id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/category/delete/" + id, Http.getAuthToken());
};
