import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { CategoryAddListValiator } from "../../validators/Validators";
import {
  getCatRequest,
  postCatRequest,
  updateCatRequest,
  deleteCatRequest,
} from "../../store/category/category.action";
import { CatAddSelector } from "../../store/category/category.model";

function Pagecategories() {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(CategoryAddListValiator),
  });

  // Selectors
  const { getCatData, sendSuccess, catUpdatedSuccess, catDeleteSuccess } =
    useSelector(CatAddSelector);

  // States
  const [id, setId] = useState(0);

  const [page, setPage] = useState(1);
  // const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  /* Function */
  const totalPages1 = Math.ceil(getCatData?.count / rowsPerPage) || 1;
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };
  const onSubmitHandler = (formData) => {
    if (id === 0) {
      dispatch(postCatRequest(formData));
    } else {
      dispatch(updateCatRequest({ formData, id }));
      setId(0);
    }
  };
  const currentPage = page;
  const prevPage = currentPage > 1 ? currentPage - 1 : null;
  const nextPage1 = currentPage < totalPages1 ? currentPage + 1 : null;
  const nextPage2 =
    totalPages1 != currentPage
      ? currentPage < totalPages1
        ? currentPage + 2
        : null
      : null;
  const lastPage_before = totalPages1 != currentPage ? "..." : null;
  const lastPage = totalPages1 != currentPage ? totalPages1 : null;
  const pagesToDisplay = [
    prevPage,
    currentPage,
    nextPage1,
    nextPage2,
    lastPage_before,
    lastPage,
  ].filter((page) => page !== null);

  const handleEdit = (cat) => {
    setId(cat.id);
    reset({
      name: cat.name,
      description: cat.description,
    });
  };

  const handleDelete = (id) => {
    dispatch(deleteCatRequest(id));
  };

  useEffect(() => {
    const payload = {
      query: searchQuery,
      page: currentPage,
      limit: rowsPerPage,
    };
    dispatch(getCatRequest(payload));
  }, [searchQuery, currentPage, rowsPerPage]);

  useEffect(() => {
    if (sendSuccess || catUpdatedSuccess || catDeleteSuccess) {
      const payload = {
        query: searchQuery,
        page: currentPage,
        limit: rowsPerPage,
      };
      dispatch(getCatRequest(payload));
      reset({
        description: "",
        name: "",
      });
      setId(0);
    }
  }, [sendSuccess, catUpdatedSuccess, catDeleteSuccess, dispatch, reset]);

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Categories</h2>
              <p>Add, edit or delete a category</p>
            </div>
            <div>
              <input
                className="form-control bg-white"
                type="text"
                placeholder="Search. . ."
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <Row>
                <Col md={3}>
                  {/* Form for adding/updating categories */}
                  <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="mb-4">
                      <label className="form-label" htmlFor="product_name">
                        Name
                      </label>
                      <input
                        className="form-control"
                        id="product_name"
                        type="text"
                        placeholder="Type here"
                        {...register("name")}
                      />
                      {errors.name && (
                        <p className="text-danger">{errors.name.message}</p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label className="form-label">Description</label>
                      <textarea
                        className="form-control"
                        placeholder="Type here"
                        {...register("description")}
                      ></textarea>
                      {errors.description && (
                        <p className="text-danger">
                          {errors.description.message}
                        </p>
                      )}
                    </div>
                    <div className="d-grid">
                      <button className="btn btn-primary" type="submit">
                        {id === 0 ? "Create category" : "Update category"}
                      </button>
                    </div>
                  </form>
                </Col>

                <Col md={9}>
                  {/* Category List */}
                  <div className="table-responsive">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                          <th>Description</th>
                          <th>Created At</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getCatData?.data &&
                          getCatData?.data?.map((ele, ind) => {
                            return (
                              <tr key={ele.id}>
                                <td>{ind + 1}</td>
                                <td>{ele.name}</td>
                                <td>{ele.description}</td>
                                <td>
                                  {dayjs(ele.created_at).format("DD-MM-YY")}
                                </td>
                                <td>
                                  <div className="dropdown">
                                    <a
                                      className="btn btn-light rounded btn-sm font-sm"
                                      href="#"
                                      data-bs-toggle="dropdown"
                                    >
                                      <i className="material-icons md-more_horiz"></i>
                                    </a>
                                    <div className="dropdown-menu">
                                      <button
                                        className="dropdown-item"
                                        onClick={() => handleEdit(ele)}
                                      >
                                        Edit
                                      </button>
                                      <button
                                        className="dropdown-item text-danger"
                                        onClick={() => handleDelete(ele.id)}
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                  <div className="pagination-area mt-15 mb-50">
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li
                          className={`page-item ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                        >
                          <a
                            className="page-link"
                            href="#"
                            onClick={() => handlePageChange(currentPage - 1)}
                          >
                            <i className="material-icons md-chevron_left"></i>
                          </a>
                        </li>

                        {pagesToDisplay.map((page) => {
                          return page != "..." ? (
                            <li
                              key={page}
                              className={`page-item b ${
                                currentPage === page ? "active" : ""
                              }`}
                            >
                              <a
                                className="page-link"
                                href="#"
                                onClick={() => handlePageChange(page)}
                              >
                                {page}
                              </a>
                            </li>
                          ) : page == "..." ? (
                            <li
                              key={page}
                              className={`page-item ${
                                currentPage === page ? "active" : ""
                              }`}
                            >
                              <a className="page-link" href="#">
                                {page}
                              </a>
                            </li>
                          ) : (
                            ""
                          );
                        })}

                        <li
                          className={`page-item ${
                            currentPage === totalPages1 ? "disabled" : ""
                          }`}
                        >
                          <a
                            className="page-link"
                            href="#"
                            onClick={() => handlePageChange(currentPage + 1)}
                          >
                            <i className="material-icons md-chevron_right"></i>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
}

export default Pagecategories;
