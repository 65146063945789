import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import brand3 from "../../assets/imgs/brands/brand-3.jpg";
import map from "../../assets/imgs/misc/map.jpg";
import item1 from "../../assets/imgs/items/1.jpg";
import item2 from "../../assets/imgs/items/2.jpg";
import item3 from "../../assets/imgs/items/3.jpg";
import item4 from "../../assets/imgs/items/4.jpg";
import item5 from "../../assets/imgs/items/5.jpg";
import item6 from "../../assets/imgs/items/6.jpg";
import item7 from "../../assets/imgs/items/7.jpg";
import item8 from "../../assets/imgs/items/8.jpg";
import item9 from "../../assets/imgs/items/9.jpg";
import item10 from "../../assets/imgs/items/10.jpg";
import item11 from "../../assets/imgs/items/11.jpg";
import item12 from "../../assets/imgs/items/12.jpg";

function Pagesellerdetail(){
    return(
        <>
        <Sidebar/>
        <main className="main-wrap">
        <Header/>

        <section className="content-main">
        <div className="content-header"><a href="javascript:history.back()"><i className="material-icons md-arrow_back"></i> Go back</a></div>
        <div className="card mb-4">
          <div className="card-header bg-primary" style={{height:'150px'}}></div>
          <div className="card-body">
            <Row>
              <div className="col-xl col-lg flex-grow-0" style={{flexBasis:'230px'}}>
                <div className="img-thumbnail shadow w-100 bg-white position-relative text-center" style={{height:'190px', width:'200px', marginTop:'-120px'}}><img className="center-xy img-fluid" src={brand3} alt="Logo Brand"/></div>
              </div>
              <div className="col-xl col-lg">
                <h3>Cocorico sports shop</h3>
                <p>3891 Ranchview Dr. Richardson, California 62639</p>
              </div>
              <div className="col-xl-4 text-md-end">
                <select className="form-select w-auto d-inline-block">
                  <option>Actions</option>
                  <option>Disable shop</option>
                  <option>Analyze</option>
                  <option>Something</option>
                </select><a className="btn btn-primary" href="#">View live<i className="material-icons md-launch"></i></a>
              </div>
              <hr className="my-4"/>
              <Row className="g-4">
                <Col md={12} lg={4} xl={2}>
                  <article className="box">
                    <p className="mb-0 text-muted">Total sales:</p>
                    <h5 className="text-success">238</h5>
                    <p className="mb-0 text-muted">Revenue:</p>
                    <h5 className="text-success mb-0">$2380</h5>
                  </article>
                </Col>
                <Col sm={6} lg={4} xl={3}>
                  <h6>Contacts</h6>
                  <p>Manager: Jerome Bell<br/>                                info@example.com<br/>                                (229) 555-0109, (808) 555-0111</p>
                </Col>
                <Col sm={6} lg={4} xl={3}>
                  <h6>Address</h6>
                  <p>Country: California<br/>                                Address: Ranchview Dr. Richardson<br/>                                Postal code: 62639</p>
                </Col>
                <Col sm={6} xl={4} className="text-xl-end"><map className="mapbox position-relative d-inline-block"><img className="rounded2" src={map} alt="map" height="120"/><span className="map-pin" style={{top:'50px', left: '100px'}}></span>
                    <button className="btn btn-sm btn-brand position-absolute bottom-0 end-0 mb-15 mr-15 font-xs"> Large</button></map></Col>
                </Row>
            </Row>
          </div>
        </div>
        <div className="card mb-4">
          <div className="card-body">
            <h5 className="card-title">Products by seller</h5>
            <Row>
              <Col xl={2} lg={3} md={6}>
                <div className="card card-product-grid"><a className="img-wrap" href="#"><img src={item1} alt="Product"/></a>
                  <div className="info-wrap"><a className="title" href="#">Product name</a>
                    <div className="price mt-1">$179.00</div>
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={3} md={6}>
                <div className="card card-product-grid"><a className="img-wrap" href="#"><img src={item2} alt="Product"/></a>
                  <div className="info-wrap"><a className="title" href="#">Product name</a>
                    <div className="price mt-1">$179.00</div>
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={3} md={6}>
                <div className="card card-product-grid"><a className="img-wrap" href="#"><img src={item3} alt="Product"/></a>
                  <div className="info-wrap"><a className="title" href="#">Jeans short new model</a>
                    <div className="price mt-1">$179.00</div>
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={3} md={6}>
                <div className="card card-product-grid"><a className="img-wrap" href="#"><img src={item4} alt="Product"/></a>
                  <div className="info-wrap"><a className="title" href="#">Travel Bag XL</a>
                    <div className="price mt-1">$179.00</div>
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={3} md={6}>
                <div className="card card-product-grid"><a className="img-wrap" href="#"><img src={item5} alt="Product"/></a>
                  <div className="info-wrap"><a className="title" href="#">Product name</a>
                    <div className="price mt-1">$179.00</div>
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={3} md={6}>
                <div className="card card-product-grid"><a className="img-wrap" href="#"><img src={item6} alt="Product"/></a>
                  <div className="info-wrap"><a className="title" href="#">Product name</a>
                    <div className="price mt-1">$179.00</div>
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={3} md={6}>
                <div className="card card-product-grid"><a className="img-wrap" href="#"><img src={item7} alt="Product"/></a>
                  <div className="info-wrap"><a className="title" href="#">Product name</a>
                    <div className="price mt-1">$179.00</div>
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={3} md={6}>
                <div className="card card-product-grid"><a className="img-wrap" href="#"><img src={item8} alt="Product"/></a>
                  <div className="info-wrap"><a className="title" href="#">Apple Airpods CB-133</a>
                    <div className="price mt-1">$179.00</div>
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={3} md={6}>
                <div className="card card-product-grid"><a className="img-wrap" href="#"><img src={item9} alt="Product"/></a>
                  <div className="info-wrap"><a className="title" href="#">Product name</a>
                    <div className="price mt-1">$179.00</div>
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={3} md={6}>
                <div className="card card-product-grid"><a className="img-wrap" href="#"><img src={item10} alt="Product"/></a>
                  <div className="info-wrap"><a className="title" href="#">Product name</a>
                    <div className="price mt-1">$179.00</div>
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={3} md={6}>
                <div className="card card-product-grid"><a className="img-wrap" href="#"><img src={item11} alt="Product"/></a>
                  <div className="info-wrap"><a className="title" href="#">Jeans short new model</a>
                    <div className="price mt-1">$179.00</div>
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={3} md={6}>
                <div className="card card-product-grid"><a className="img-wrap" href="#"><img src={item12} alt="Product"/></a>
                  <div className="info-wrap"><a className="title" href="#">Travel Bag XL</a>
                    <div className="price mt-1">$179.00</div>
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={3} md={6}>
                <div className="card card-product-grid"><a className="img-wrap" href="#"><img src={item1} alt="Product"/></a>
                  <div className="info-wrap"><a className="title" href="#">Product name</a>
                    <div className="price mt-1">$179.00</div>
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={3} md={6}>
                <div className="card card-product-grid"><a className="img-wrap" href="#"><img src={item2} alt="Product"/></a>
                  <div className="info-wrap"><a className="title" href="#">Product name</a>
                    <div className="price mt-1">$179.00</div>
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={3} md={6}>
                <div className="card card-product-grid"><a className="img-wrap" href="#"><img src={item3} alt="Product"/></a>
                  <div className="info-wrap"><a className="title" href="#">Jeans short new model</a>
                    <div className="price mt-1">$179.00</div>
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={3} md={6}>
                <div className="card card-product-grid"><a className="img-wrap" href="#"><img src={item4} alt="Product"/></a>
                  <div className="info-wrap"><a className="title" href="#">Travel Bag XL</a>
                    <div className="price mt-1">$179.00</div>
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={3} md={6}>
                <div className="card card-product-grid"><a className="img-wrap" href="#"><img src={item5} alt="Product"/></a>
                  <div className="info-wrap"><a className="title" href="#">Product name</a>
                    <div className="price mt-1">$179.00</div>
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={3} md={6}>
                <div className="card card-product-grid"><a className="img-wrap" href="#"><img src={item6} alt="Product"/></a>
                  <div className="info-wrap"><a className="title" href="#">Product name</a>
                    <div className="price mt-1">$179.00</div>
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={3} md={6}>
                <div className="card card-product-grid"><a className="img-wrap" href="#"><img src={item7} alt="Product"/></a>
                  <div className="info-wrap"><a className="title" href="#">Product name</a>
                    <div className="price mt-1">$179.00</div>
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={3} md={6}>
                <div className="card card-product-grid"><a className="img-wrap" href="#"><img src={item8} alt="Product"/></a>
                  <div className="info-wrap"><a className="title" href="#">Apple Airpods CB-133</a>
                    <div className="price mt-1">$179.00</div>
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={3} md={6}>
                <div className="card card-product-grid"><a className="img-wrap" href="#"><img src={item9} alt="Product"/></a>
                  <div className="info-wrap"><a className="title" href="#">Product name</a>
                    <div className="price mt-1">$179.00</div>
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={3} md={6}>
                <div className="card card-product-grid"><a className="img-wrap" href="#"><img src={item10} alt="Product"/></a>
                  <div className="info-wrap"><a className="title" href="#">Product name</a>
                    <div className="price mt-1">$179.00</div>
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={3} md={6}>
                <div className="card card-product-grid"><a className="img-wrap" href="#"><img src={item11} alt="Product"/></a>
                  <div className="info-wrap"><a className="title" href="#">Jeans short new model</a>
                    <div className="price mt-1">$179.00</div>
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={3} md={6}>
                <div className="card card-product-grid"><a className="img-wrap" href="#"><img src={item12} alt="Product"/></a>
                  <div className="info-wrap"><a className="title" href="#">Travel Bag XL</a>
                    <div className="price mt-1">$179.00</div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="pagination-area mt-30 mb-50">
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-start">
              <li className="page-item active"><a className="page-link" href="#">01</a></li>
              <li className="page-item"><a className="page-link" href="#">02</a></li>
              <li className="page-item"><a className="page-link" href="#">03</a></li>
              <li className="page-item"><a className="page-link dot" href="#">...</a></li>
              <li className="page-item"><a className="page-link" href="#">16</a></li>
              <li className="page-item"><a className="page-link" href="#"><i className="material-icons md-chevron_right"></i></a></li>
            </ul>
          </nav>
        </div>
      </section>

        <Footer/>
        </main>
        </>
    );
}
export default Pagesellerdetail;