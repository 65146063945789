import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";

export const signIn = (postData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/auth/signin", postData);
};

export const getusersocials = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/social/user_socials", Http.getAuthToken());
};

export const getUserDataApi = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/user?query=" +
      payload.query +
      "&page=" +
      payload.page +
      "&limit=" +
      payload.limit+
      "&userId="+payload.userId,
    Http.getAuthToken()
  );
};
export const createUserApi = (postData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/user/", postData, Http.getAuthToken());
};
export const updateUserApi = (postData) => {
  console.log(postData, "678")
  const baseUrl = getBaseEndpointUrl();
  return Http.put(baseUrl + "/user/"+postData.id,postData.formData, Http.getAuthToken());
};

export const deleteUserApi = (postData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.delete(baseUrl + "/user/"+postData, Http.getAuthToken());
};