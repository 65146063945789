import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";

function Pageorderstracking(){
    return(
        <>
        
        <Sidebar/>
        <main className="main-wrap">
        <Header/>

        <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">Order Tracking</h2>
            <p>Details for Order ID: 3453012</p>
          </div>
        </div>
        <div className="card">
          <header className="card-header">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 mb-lg-0 mb-15"><span><i className="material-icons md-calendar_today"></i><b>Wed, Aug 13, 2022, 4:34PM</b></span><br/><small className="text-muted">Order ID: 3453012</small><br/><small className="text-muted">Your order has been delivered</small></div>
              <div className="col-lg-6 col-md-6 ms-auto text-md-end">
                <select className="form-select d-inline-block mb-lg-0 mb-15 mw-200 mr-15">
                  <option>Change status</option>
                  <option>Awaiting payment</option>
                  <option>Confirmed</option>
                  <option>Shipped</option>
                  <option>Delivered</option>
                </select><a className="btn btn-primary" href="#">Screenshot</a><a className="btn btn-secondary print ms-2" href="#"><i className="icon material-icons md-print mr-5"></i>Print</a>
              </div>
            </div>
          </header>
          <div className="card-body">
            <div className="order-tracking mb-100">
              <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between">
                <div className="step completed">
                  <div className="step-icon-wrap">
                    <div className="step-icon"><i className="material-icons md-shopping_cart"></i></div>
                  </div>
                  <h4 className="step-title">Confirmed Order</h4><small className="text-muted text-sm">15 March 2022</small>
                </div>
                <div className="step completed">
                  <div className="step-icon-wrap">
                    <div className="step-icon"><i className="material-icons md-settings"></i></div>
                  </div>
                  <h4 className="step-title">Processing Order</h4><small className="text-muted text-sm">16 March 2022</small>
                </div>
                <div className="step completed">
                  <div className="step-icon-wrap">
                    <div className="step-icon"><i className="material-icons md-shopping_bag"></i></div>
                  </div>
                  <h4 className="step-title">Quality Check</h4><small className="text-muted text-sm">17 March 2022</small>
                </div>
                <div className="step">
                  <div className="step-icon-wrap">
                    <div className="step-icon"><i className="material-icons md-local_shipping"></i></div>
                  </div>
                  <h4 className="step-title">Product Dispatched</h4><small className="text-muted text-sm">18 March 2022</small>
                </div>
                <div className="step">
                  <div className="step-icon-wrap">
                    <div className="step-icon"><i className="material-icons md-check_circle"></i></div>
                  </div>
                  <h4 className="step-title">Product Delivered</h4><small className="text-muted text-sm">20 March 2022</small>
                </div>
              </div>
            </div>
            <div className="row mb-50 mt-20 order-info-wrap text-center">
              <div className="col-md-4">
                <article className="icontext align-items-start">
                  <div className="text">
                    <h6 className="mb-1">Customer</h6>
                    <p className="mb-1">John Alexander<br/> alex@example.com<br/> +998 99 22123456</p><a href="#">View profile</a>
                  </div>
                </article>
              </div>
              <div className="col-md-4">
                <article className="icontext align-items-start">
                  <div className="text">
                    <h6 className="mb-1">Order info</h6>
                    <p className="mb-1">Shipping: Fargo express<br/> Pay method: card<br/> Status: new</p><a href="#">Download info</a>
                  </div>
                </article>
              </div>
              <div className="col-md-4">
                <article className="icontext align-items-start">
                  <div className="text">
                    <h6 className="mb-1">Deliver to</h6>
                    <p className="mb-1">City: Tashkent, Uzbekistan<br/>Block A, House 123, Floor 2<br/> Po Box 10000</p><a href="#">View profile</a>
                  </div>
                </article>
              </div>
            </div>
            <div className="row">
              <div className="text-center mt-100 mb-50"><a className="btn btn-primary" href="page-orders-detail.html">View Order Details</a></div>
            </div>
          </div>
        </div>
      </section>

        <Footer/>
        </main>
        </>
    );
}
export default Pageorderstracking;