import { call, put, takeEvery } from "redux-saga/effects";
import {
  deleteBrandergoryApi,
  getBrandApi,
  postBrandApi,
  updateBrandergoryApi,
} from "./brand.api";
import * as BrandAddAction from "./brand.action";
import { BrandActionType } from "./brand.model";
import Swal from "sweetalert2";

function* handlepostData(action) {
  try {
    const response = yield call(postBrandApi, action.payload);
    yield put(BrandAddAction.postBrandSuccess(response));
    Swal.fire({
      title: "Success",
      text: "Brand List Added Succesfully!",
      icon: "success",
    });
  } catch (e) {
    yield put(BrandAddAction.postBrandFailure(e));
    Swal.fire({
      title: "Error",
      text: e?.response?.data?.message,
      icon: "error",
    });
  }
}

export function* handlegetData(action) {
  try {
    const response = yield call(getBrandApi, action.payload);
    yield put(BrandAddAction.getBrandSuccess(response));
  } catch (e) {
    yield put(BrandAddAction.getBrandFailure(e));
  }
}

function* handleUpdateBrandData(action) {
  try {
    const response = yield call(updateBrandergoryApi, action.payload);
    yield put(BrandAddAction.updateBrandSuccess(response));

    Swal.fire({
      title: "Success",
      text: "Brand List Updated Succesfully!",
      icon: "success",
    });
  } catch (e) {
    yield put(BrandAddAction.updateBrandFailure(e));
    Swal.fire({
      title: "Error",
      text: e?.response?.data?.message,
      icon: "error",
    });
  }
}
function* handleDeleteBrandData(action) {
  try {
    const response = yield call(deleteBrandergoryApi, action.payload);
    yield put(BrandAddAction.deleteBrandSuccess(response));

    Swal.fire({
      title: "Success",
      text: "Brand List Delete Succesfully!",
      icon: "success",
    });
  } catch (e) {
    yield put(BrandAddAction.deleteBrandFailure(e));
    Swal.fire({
      title: "Error",
      text: e?.response?.data?.message,
      icon: "error",
    });
  }
}

export function* BrandSaga() {
  yield takeEvery(BrandActionType.POSTBRAND_REQUEST, handlepostData);
  yield takeEvery(BrandActionType.GETBRAND_REQUEST, handlegetData);
  yield takeEvery(BrandActionType.UPDATEBRAND_REQUEST, handleUpdateBrandData);
  yield takeEvery(BrandActionType.DELETEBRAND_REQUEST, handleDeleteBrandData);
}
