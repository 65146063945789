export const orderActionTypes = {
  GETUSERORDER_REQUEST: "@@order/GETUSERORDER_REQUEST",
  GETUSERORDER_SUCCESS: "@@order/GETUSERORDER_SUCCESS",
  GETUSERORDER_FAILURE: "@@order/GETUSERORDER_FAILURE",

  ORDERDISPATCH_REQUEST: "@@order/ORDERDISPATCH_REQUEST",
  ORDERDISPATCH_SUCCESS: "@@order/ORDERDISPATCH_SUCCESS",
  ORDERDISPATCH_FAILURE: "@@order/ORDERDISPATCH_FAILURE",

  ORDERDETAILBYID_REQUEST: "@@order/ORDERDETAILBYID_REQUEST",
  ORDERDETAILBYID_SUCCESS: "@@order/ORDERDETAILBYID_SUCCESS",
  ORDERDETAILBYID_FAILURE: "@@order/ORDERDETAILBYID_FAILURE",

  ORDERDSTATUSUPDADE_REQUEST: "@@order/ORDERDSTATUSUPDADE_REQUEST",
  ORDERDSTATUSUPDADE_SUCCESS: "@@order/ORDERDSTATUSUPDADE_SUCCESS",
  ORDERDSTATUSUPDADE_FAILURE: "@@order/ORDERDSTATUSUPDADE_FAILURE",
  
  GENERATE_REQUEST: "@@order/GENERATE_REQUEST",
  GENERATE_SUCCESS: "@@order/GENERATE_SUCCESS",
  GENERATE_FAILURE: "@@order/GENERATE_FAILURE",
  
  ORDERSTATE_RESET_REQUEST: "@@order/ORDERSTATE_RESET_REQUEST",
};

export const orderInitialState = {
  userOrderLoading: false,
  userOrderData: [],
  orderDisLoad: false,
  orderDetailLoad: false,
  orderDetailData: [],
  updateStatusLoad: false,
  updateStatusSuccess: false,
  pdfLoad:false,
  pdfSendsuccess:"",
  getpdfDataSuccess:false
};
export const orderSelector = (state) => state.orderReducer;
