import { all, call, put, takeEvery } from "redux-saga/effects";
import * as UserActions from "./user.action";
import { UserActionTypes } from "./user.model";
import Swal from "sweetalert2";
import {
  createUserApi,
  deleteUserApi,
  getUserDataApi,
  getusersocials,
  signIn,
  updateUserApi,
} from "./user.api";

// export function* handleUpdateUser(action) {
//   try {
//     const response = yield call(signIn, action.payload);
//     yield put(UserActions.updateUserSuccess(response));
//     Swal.fire({
//       title: "Updated Settings",
//       text: "Settings updated successfully",
//       icon: "success",
//     });
//   } catch (e) {
//     yield put(UserActions.updateUserFailure(e));
//   }
// }

export function* handleUserSocials(action) {
  try {
    const response = yield call(getusersocials);
    yield put(UserActions.getusersocialsSuccess(response));
  } catch (error) {
    yield put(UserActions.getusersocialsFailure(error));
  }
}

// export function* handleVerifyPin(action) {
//     try {
//         const response = yield call(UserAPI.VerifyPin, action.payload);
//         yield put(UserActions.verifyPinSuccess(response));
//         yield put(UserActions.fetchCoinBalanceRequest(response.wallets));
//     } catch (e) {
//         Swal.fire({
//             title: "Invalid PIN",
//             text: "Invalid PIN. Please enter correct pin",
//             icon: "error"
//         });
//         yield put(UserActions.verifyPinFailure(e));
//     }
// }

// export function* handleUpdateToken() {
//     try {
//         const response = yield call(UserAPI.UpdateNotificationToken);
//         yield put(UserActions.updateTokenSuccess(response));
//     } catch (e) {
//         Swal.fire({
//             title: "Notification",
//             text: "Unable to set Notifications",
//             icon: "error"
//         });
//         yield put(UserActions.updateTokenFailure(e));
//     }
// }

// export function* handleGetStats() {
//     try {
//         const response = yield call(UserAPI.GetStats);
//         yield put(UserActions.getStatisticsSuccess(response));
//     } catch (e) {
//         yield put(UserActions.getStatisticsFailure(e));
//     }
// }

// export function* handleCoinBalance(action) {
//     try {
//         const response = yield all(action.payload.map(x => call(UserAPI.GetCoinBalnce, x)));
//         yield put(UserActions.fetchCoinBalanceSuccess(response));
//     } catch (e) {
//         yield put(UserActions.fetchCoinBalanceFailure(e));
//     }
// }

// export function* handleUpdateSetting(action) {
//     try {
//         const response = yield call(UserAPI.UpdateSetting, action.payload);
//         yield put(UserActions.updateSettingsSuccess(response));
//         Swal.fire({
//             title: "Updated Settings",
//             text: "Settings updated successfully",
//             icon: "success"
//         });
//     } catch (e) {
//         yield put(UserActions.updateSettingsFailure(e));
//     }
// }
function* handlegetUserData(action) {
  try {
    const response = yield call(getUserDataApi, action.payload);
    yield put(UserActions.getUserDataSuccess(response));
  } catch (e) {
    yield put(UserActions.getUserDataFailure(e));
  }
}
function* handleCreateUser(action) {
  try {
    const response = yield call(createUserApi, action.payload);
    yield put(UserActions.createUserDataSuccess(response));
  } catch (e) {
    yield put(UserActions.createUserDataFailure(e));
  }
}
function* handleUpdateUserData(action) {
  console.log(action.payload, "actusdjf")
  try {
    const response = yield call(updateUserApi, action.payload);
    yield put(UserActions.updateUserSuccess(response));
  } catch (e) {
    yield put(UserActions.updateUserFailure(e));
  }
}

function* handleDeleteUser(action) {
  try {
    const response = yield call(deleteUserApi, action.payload);
    yield put(UserActions.deleteUserDataSuccess(response));
  } catch (e) {
    yield put(UserActions.deleteUserDataFailure(e));
  }
}

export function* UserSaga() {
  yield takeEvery(UserActionTypes.GET_USERSOCIAL_REQUEST, handleUserSocials);
  // yield takeEvery(UserActionTypes.UPDATE_USER_REQUEST, handleUpdateUser);
  yield takeEvery(UserActionTypes.GETUSERDATA_REQUEST, handlegetUserData);
  yield takeEvery(UserActionTypes.CREATEUSER_REQUEST, handleCreateUser);
  yield takeEvery(UserActionTypes.UPDATE_USER_REQUEST, handleUpdateUserData);
  yield takeEvery(UserActionTypes.DELETEUSERDATA_REQUEST, handleDeleteUser);

  // yield takeEvery(UserActionTypes.VERIFY_PIN_REQUEST, handleVerifyPin);
  // yield takeEvery(UserActionTypes.UPDATE_TOKEN_REQUEST, handleUpdateToken);
  // yield takeEvery(UserActionTypes.GET_STATUS_REQUEST, handleGetStats);
  // yield takeEvery(UserActionTypes.FETCH_BALANCE_REQUEST, handleCoinBalance);
  // yield takeEvery(UserActionTypes.UPDATE_SETTINGS_REQUEST, handleUpdateSetting);
}
